/* eslint-disable no-console */
import { PlusOutlined } from '@ant-design/icons';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  addEntity,
  getCompany,
  getCountries,
  getState,
} from 'redux/actions/CompaniesAction';
import { findCompanyName } from 'redux/actions/UtilsActions';
import { RESET_BLOCK_COMPANY } from 'redux/reducers/CompaniesReducer';
import { businessTypes } from 'utils/helper';
import Layout, { OverflowWrapLayout } from '../Layout';
import { sliderContents, stepsObject } from '../config';
import '../styles.scss';
import { get_user_profile } from 'redux/actions/AuthAction';

const FindCompany = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  // const {
  //   user: { data = {} },
  //   updatedUser: { loading },
  // } = useSelector(({ auth }) => auth);
  const menuPortalTarget = document.body;
  const {
    findCompanyName: {
      data: compnayName,
      loading: loadingCompnayName,
      success: successCompnayName,
      error,
    } = {},
  } = useSelector(({ utils }) => utils);

  const {
    user: { data: profile, loading: loadingUser, success: successUser },
  } = useSelector(({ auth }) => auth);

  const token = localStorage.getItem('app-session');

  const {
    getState: { data: states = {}, success: successState, loading: loadingState },
    getCountry: { data: countryData = {}, loading: loadingCountry },
    addEntity: { loading: entityLoading, success: entitySuccess, data: newCompany },
  } = useSelector(({ companies }) => companies);

  const [addNewCompany, setAddNewCompany] = useState(false);

  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [inputBlurred, setInputBlurred] = useState(false);

  const handleSelectChange = (val) => {
    if (!val) {
      setIsOpen(false);
    }

    setValue(val);
  };

  useEffect(() => {
    if (!token) return push('/login');
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const [user, setUser] = useState({
    company: '',
    country: '',
  });

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData
        ?.filter((item) => item.name === 'NIGERIA')
        ?.map(({ code: value, name: label }) => ({
          value,
          label,
          isDisabled: label !== 'NIGERIA' ? true : false,
        }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const mappedDoc = compnayName?.map((item) => item.name);
  const companiesList = useMemo(() => {
    return compnayName?.map((item) => ({
      ...item,
      name: item?.name,
      rcNumber: item?.rcNumber,
      street: item?.address,
      city: item?.city || '',
      state: item?.state || '',
      country: generateCountry?.find((i) => i.label === 'NIGERIA'),
      label: item.name,
      value: item.code,
    }));
  }, [successCompnayName, error, mappedDoc]);

  useEffect(() => {
    if (entitySuccess) {
      dispatch(get_user_profile());
    }
  }, [entitySuccess]);

  useEffect(() => {
    if (profile?.user?.company?.code)
      push({ pathname: '/company-solution', state: { code: newCompany.code } });
  }, [profile?.user?.company?.code]);

  const handleChange = ({ target: { name, value } }) => {
    setUser({ ...user, [name]: value });
  };

  const isCompanyLookup = user?.company?.value?.startsWith('clk_');

  const handleSubmit = () => {
    if (user?.company) return toastError('Please find your company to continue');

    let payloadData = { lookedUpCompany: user?.company?.value };

    // dispatch(update_user_profile(payloadData));
  };

  const handleCreateCompany = () => {
    if (!user.name) return toastError('Please enter business legal name');
    if (!user.businessType) return toastError('Please select registration type');
    if (!user.rcNumber) return toastError('Please enter company number');
    if (!user.street) return toastError('Please enter address');
    if (!user.country) return toastError('Please select a country');
    if (!user.state) return toastError('Please select a state');
    if (!user.city) return toastError('Please enter city');

    const payload = {
      name: user?.name,
      businessType: user?.businessType?.value,
      [user?.businessType?.key]: user?.rcNumber,
      address: {
        country: user?.country?.value,
        state: user?.state?.value,
        city: user.city,
        street: user.street,
      },
      cards: true,
      invoices: true,
    };
    dispatch(addEntity(payload));
  };

  const handleNoOptionsMessage = () => {
    if (value.trim() !== '' && value.length > 4) {
      return loadingCompnayName ? 'Searching please wait...' : 'No options found.';
    }
    return 'Type to search...';
  };

  const handleBlur = () => {
    setInputBlurred(false);

    if (value) {
      setUser({ ...user, company: { value, label: value } });
      setTimeout(() => {
        handleSelectChange(value);
      }, 500);
    }
  };

  const handleSearch = () => {
    dispatch(findCompanyName({ search: debouncedValue?.toLowerCase() }));
    setIsOpen(true);
  };

  const handleClear = () => {
    dispatch({ type: RESET_BLOCK_COMPANY, blockType: 'findCompanyName' });
  };

  const numberType = { rcNumber: '(RC Number)', bnNumber: '(BN Number)' };

  const CustomOption = (props) => {
    const { innerProps, innerRef, data } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{ zIndex: 100000000 }}
        className="gap-2 format-lookup-option"
      >
        <div>
          <h4 className="name">{data.label}</h4>
          <p>{data?.rcNumber}</p>
        </div>

        <p>{data?.street}</p>
      </div>
    );
  };

  const findCompany = (
    <>
      <Row className="mb-3">
        <CustomSelect
          label="Where is your company registered?"
          name="country"
          placeholder="Select country"
          onChange={(val) => setUser({ ...user, country: val })}
          value={user.country}
          options={generateCountry}
          isLoading={loadingCountry}
          isDisabled={loadingCountry}
          menuPortalTarget={menuPortalTarget}
          isCountry
        />
      </Row>

      <Row className="mb-4 d-flex align-items-end">
        <div style={{ width: '80%' }}>
          <CustomSelect
            customStyles={{ option: { borderBottom: 'none !important' } }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            noOptionsMessage={handleNoOptionsMessage}
            // noOptionsMessage={() => null}
            label="Legal name or registration number"
            name="company"
            onclickExtended={() => {
              setAddNewCompany(true);
              setUser({
                ...user,
                name: value,
              });
            }}
            extendComponent={
              <span className="d-flex align-items-center gap-2">
                <PlusOutlined />
                Add company manually
              </span>
            }
            onBlur={handleBlur}
            placeholder="e.g. Bujeti Limited"
            onInputChange={(inputValue) => {
              if (inputBlurred) {
                handleSelectChange(inputValue);
              }
            }}
            onFocus={() => setInputBlurred(true)}
            // onBlur={() => setIsFocused(false)}
            onChange={(val) => {
              setUser({ ...user, ...val, company: val });
              setAddNewCompany(true);
              getCountryStates(val?.country);
              handleClear();
            }}
            value={user.company}
            inputValue={value}
            // onKeyDown={handleKeyDown}
            options={companiesList}
            isLoading={loadingCompnayName && !debouncedValue}
            isDisabled={loadingCompnayName && !debouncedValue}
            menuIsOpen={isOpen ? true : false}
            onMenuClose={handleClear}
            menuPortalTarget={menuPortalTarget}
            customOption={CustomOption}
          />
        </div>
        <button
          type="button"
          disabled={
            loadingCompnayName || (!debouncedValue?.length && debouncedValue?.length < 5)
          }
          onClick={handleSearch}
          className="primary-button cursor"
        >
          Search
        </button>
      </Row>
    </>
  );

  const createCompany = (
    <>
      <Row className="mb-3">
        <CustomInput
          label="Legal business name"
          placeholder="Legal business name"
          type="text"
          name="name"
          onChange={handleChange}
          value={user.name}
          required
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Registration type"
          name="businessType"
          placeholder="Select type"
          onChange={(val) => setUser({ ...user, businessType: val })}
          value={user.businessType}
          options={businessTypes}
          menuPortalTarget={menuPortalTarget}
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label={`Registration number ${
            user?.businessType?.key ? numberType[user?.businessType?.key] : ''
          }`}
          placeholder="Enter number"
          type="text"
          name="rcNumber"
          onChange={handleChange}
          value={user.rcNumber}
          required
          maxLength="9"
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="Legal business address"
          placeholder="Plot 1B, Block 129, Jide Sawyerr Drive"
          type="text"
          name="street"
          onChange={handleChange}
          value={user.street}
          required
        />
      </Row>

      <Row className="mb-3">
        <CustomSelect
          label="Country"
          name="country"
          placeholder="Select country"
          onChange={(val) => {
            setUser({ ...user, country: val });
            getCountryStates(val);
          }}
          value={user.country}
          options={generateCountry}
          isLoading={loadingCountry}
          isDisabled={loadingCountry}
          menuPortalTarget={menuPortalTarget}
          isCountry
        />
      </Row>

      <Row className="mb-3">
        <CustomInput
          label="City"
          placeholder="Enter city"
          type="text"
          name="city"
          onChange={handleChange}
          value={user.city}
          required
        />
      </Row>

      <Row className="mb-4 align-items-center">
        <CustomSelect
          placeholder="Select a state"
          type="text"
          name="state"
          value={user.state}
          label="State / Province"
          // isDisabled={loadingState}
          options={generateState}
          onChange={(val) => setUser({ ...user, state: val })}
          md={6}
          menuPortalTarget={menuPortalTarget}
        />
        <CustomInput
          label="Postcode"
          name="postCode"
          type="text"
          placeholder="100001"
          onChange={handleChange}
          value={user.postCode}
          maxLength="20"
          md={6}
        />
      </Row>

      <CustomButton
        type="button"
        fullWidth
        onClick={handleCreateCompany}
        loading={entityLoading || loadingUser}
        disabled={entityLoading || loadingUser}
      >
        Confirm company details
      </CustomButton>
    </>
  );

  const title = addNewCompany ? 'Provide company information' : 'Find your company';
  const subtitle = addNewCompany
    ? 'Ensure the information below matches your Corporate Affairs Commission (CAC) certificate of registration.'
    : `Search your company by legal name or registration number. We'll auto-fill the required information.`;
  return (
    <Layout slider={sliderContents} menus={stepsObject('complete-details')}>
      <AuthFormHeader
        backButton={addNewCompany}
        goBack={() => setAddNewCompany(false)}
        title={title}
        subtitle={subtitle}
      />
      <OverflowWrapLayout>
        <Form className="w-100">{addNewCompany ? createCompany : findCompany}</Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default FindCompany;
