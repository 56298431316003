import { Skeleton } from 'antd';
import CurrencyFormat from 'react-currency-format';
import { numFormatter } from 'utils/utility';

const FormattedCurrency = ({ value, prefix, loading }) => {
  const formattedValue = numFormatter((value / 100).toFixed(2));

  return (
    <>
      {loading ? (
        <Skeleton.Input
          className="mt-1"
          active
          size={20}
          style={{ borderRadius: 4, minWidth: '7rem' }}
        />
      ) : (
        <CurrencyFormat
          prefix={prefix}
          value={formattedValue}
          displayType="text"
          thousandSeparator={true}
          isNumericString
          renderText={(formattedValue) => {
            const [integerPart, decimalPart] = formattedValue.split('.');
            return (
              <span>
                {integerPart}
                {decimalPart && <span style={{ color: '#A9A29D' }}>.{decimalPart}</span>}
              </span>
            );
          }}
        />
      )}
    </>
  );
};

export default FormattedCurrency;
