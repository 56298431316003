import { useState } from 'react';

import { Tooltip } from 'antd';
import CustomPopover from 'components/UI/Popover';
import CurrencyFormat from 'react-currency-format';
import { SendFundsButton } from 'components/UI/CustomButton';

import {
  ArrowUpIcon,
  ArrowDownIcon,
  TransactionsIcon,
  InfoCircle,
  BankNoteO1Icon,
  LayersTwoIcon,
  CalendarPlusIcon,
} from 'assets/icons';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleAction } from 'redux/actions/ToggleAction';

import AllowedTo from 'utils/AllowedTo';
import { getCurrency } from 'utils/helper';
import { numFormatter } from 'utils/utility';

const FormattedCurrency = ({ value, prefix }) => {
  const formattedValue = numFormatter(value / 100);

  return (
    <CurrencyFormat
      prefix={prefix}
      value={formattedValue}
      displayType="text"
      thousandSeparator={true}
      isNumericString
      renderText={(formattedValue) => {
        const [integerPart, decimalPart] = formattedValue.split('.');
        return (
          <span>
            {integerPart}
            {decimalPart && <span style={{ color: '#BFBFBD' }}>.{decimalPart}</span>}
          </span>
        );
      }}
    />
  );
};

const Balances = ({ handleSwap, openModal, balances = {}, defaultCurrency = 'NGN' }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(1);
  const [slideDirection, setSlideDirection] = useState('');
  const [isButtonToggle, setIsButtonToggle] = useState(false);

  const handleButtonToggle = () => setIsButtonToggle(!isButtonToggle);

  const isSchedule = () => {
    localStorage.setItem('schedule', 'on');
  };

  const isDisabled = (index) => {
    return activeIndex === index;
  };

  const handleArrowClick = (index) => {
    if (isDisabled(index)) return;

    setSlideDirection('slide-out');

    setTimeout(() => {
      setActiveIndex(index);
      setSlideDirection('slide-in');
    }, 200);
  };

  const PaymentActions = () => {
    return (
      <div className="actions-dialog overview">
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
          }}
        >
          <BankNoteO1Icon /> Single payment
        </div>
        <AllowedTo
          scopes={[
            'batch-transaction-*',
            'batch-transaction-view',
            'batch-transaction-create',
          ]}
        >
          <div
            className="actionLink"
            onClick={() => {
              history.push('/transactions/batch-payment');
            }}
          >
            <LayersTwoIcon /> Batch payments
          </div>
        </AllowedTo>
        <div
          className="actionLink"
          onClick={() => {
            dispatch(toggleAction());
            handleButtonToggle();
            isSchedule();
          }}
        >
          <CalendarPlusIcon /> Schedule payment
        </div>
      </div>
    );
  };

  return (
    <section>
      <div className="d-flex w-100">
        <div className="overview-balances__holder">
          <p className="text">
            Total balance
            <Tooltip
              overlayClassName="balance-tip"
              title={
                <span style={{ fontSize: 12 }}>
                  The sum of your accounts, budgets and cards balances
                </span>
              }
            >
              <span className="icon-animate">
                <InfoCircle
                  style={{ fontSize: '14px', paddingTop: '2px' }}
                  fill="#BFBFBD"
                />
              </span>
            </Tooltip>
          </p>

          <p className="value">
            <FormattedCurrency
              value={balances?.totalBalances?.['NGN'] || 0}
              prefix={getCurrency(defaultCurrency)}
            />
          </p>
        </div>

        <div className="ms-auto d-flex gap-3">
          <div
            className="add-custom add-button overview d-flex white align-items-center cursor"
            onClick={handleSwap}
          >
            <div className="arrow-icon-wrapper">
              <TransactionsIcon stroke="#7F7F7D" className="first-svg left" />
              <TransactionsIcon stroke="#7F7F7D" className="second-svg left" />
            </div>

            <span>Internal transfer</span>
          </div>

          <div
            className="add-custom add-button overview d-flex white align-items-center cursor"
            onClick={openModal}
          >
            <div className="arrow-icon-wrapper">
              <ArrowDownIcon stroke="#7F7F7D" className="first-svg bottom" />
              <ArrowDownIcon stroke="#7F7F7D" className="second-svg bottom" />
            </div>

            <span>Deposit funds</span>
          </div>

          <AllowedTo scopes={['transaction-*', 'transaction-create']}>
            <CustomPopover
              zIndex="1"
              content={<PaymentActions />}
              showPopover={isButtonToggle}
              clickOutside={handleButtonToggle}
            >
              <SendFundsButton onClick={handleButtonToggle} />
            </CustomPopover>
          </AllowedTo>
        </div>
      </div>

      {/* Cards Here */}
      <div className="mt-3 d-flex gap-3">
        <div className="overview-display__cards">
          <p className="caption">Accounts</p>

          <p className="value">
            <FormattedCurrency
              value={balances?.summary?.[defaultCurrency] ?? 0}
              prefix={getCurrency('NGN')}
            />
          </p>
        </div>

        <div className="overview-display__cards">
          <p className="caption">Budgets</p>
          <p className="value">
            <FormattedCurrency
              value={balances?.budgetSummary?.[defaultCurrency] ?? 0}
              prefix={getCurrency(defaultCurrency)}
            />
          </p>
        </div>

        <div className="overview-display__cards">
          <p className="caption d-flex gap-1 align-items-center">
            <span className={`inner-currency__holder ${slideDirection}`}>
              <span>{activeIndex === 1 ? 'NGN' : 'USD'} </span>
            </span>
            cards
            <div className="d-flex ms-auto align-items-center gap-1">
              {Array.from({ length: 2 }).map((_, i) => (
                <span
                  key={i + 1}
                  className={`indicator-dot ${i + 1 === activeIndex ? 'active' : ''}`}
                ></span>
              ))}
            </div>
          </p>

          <div className="d-flex align-items-center">
            <div className={`value-holder ${slideDirection}`}>
              <span className="value">
                <FormattedCurrency
                  value={
                    activeIndex === 1
                      ? balances?.cardBalances?.['NGN'] || 0
                      : balances?.cardBalances?.['USD'] || 0
                  }
                  prefix={getCurrency(activeIndex === 1 ? 'NGN' : 'USD')}
                />
              </span>
            </div>

            <div className="ms-auto d-flex gap-1 align-items-center">
              <span
                className={`indicator-arrow-holder p-1 ${isDisabled(1) ? 'dormant' : ''}`}
                onClick={() => handleArrowClick(1)}
              >
                <ArrowUpIcon
                  height="16"
                  width="16"
                  stroke={isDisabled(1) ? '#BFBFBD' : '#7F7F7D'}
                  style={{ rotate: '-90deg' }}
                />
              </span>

              <span
                className={`indicator-arrow-holder p-1 ${isDisabled(2) ? 'dormant' : ''}`}
                onClick={() => handleArrowClick(2)}
              >
                <ArrowUpIcon
                  height="16"
                  width="16"
                  stroke={isDisabled(2) ? '#BFBFBD' : '#7F7F7D'}
                  style={{ rotate: '90deg' }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Balances;
