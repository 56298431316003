import { useState } from 'react';

import { BankFilled, FileDoneOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { NigeriaCrest, UnitedStateCrest, ArrowCircleRight } from 'assets/icons';
import BanksIcons from 'assets/icons/banks';
import CurrencyFormat from 'react-currency-format';
import { FormattedCurrency, getCurrency } from 'utils/helper';
import ImgCard from 'components/UI/ImgCard';
import CustomPopover from 'components/UI/Popover';
import { DotsVertical } from 'assets/icons';

const CURRENCY_TITLE = {
  NGN: 'NGN balance',
  USD: 'USD balance',
};

export const getIcon = (currency, size = '40') => {
  const ICONS = {
    NGN: <NigeriaCrest width={size} height={size} />,
    USD: <UnitedStateCrest width={size} height={size} />,
  };

  return ICONS[currency] || null;
};

export const ICONS = {
  NGN: <NigeriaCrest width="40" height="40" />,
  USD: <UnitedStateCrest width="40" height="40" />,
};

export const AccountCard = ({
  code,
  name,
  balance,
  number,
  currency,
  bankName,
  accountName,
  openBalance,
  openDetailsModal,
  openStatementModal,
  logo,
  isSubaccount = false,
  isDirectDebit = false,
  isDirectDebitActive = false,
}) => {
  const maskValue = (value) => {
    const lastFourDigits = value?.toString()?.slice(-4);
    return `••• ${lastFourDigits}`;
  };

  const handleImgError = (e) => {
    e.target.src = logo;
    e.target.onerror = null;
  };

  const [showOptions, setShowOptions] = useState(false);

  const Actions = () => {
    return (
      <div className="actions-dialog">
        <div
          className="actionLink"
          onClick={() =>
            openBalance(code, {
              name,
              balance,
              number,
              currency,
              bankName,
              accountName,
              code,
              isSubaccount,
            })
          }
        >
          <UnorderedListOutlined />
          View transfers
        </div>

        <div className="actionLink" onClick={openDetailsModal}>
          <BankFilled />
          See account details
        </div>

        <div className="actionLink" onClick={openStatementModal}>
          <FileDoneOutlined />
          Generate account Statement
        </div>
      </div>
    );
  };

  return (
    <div
      className="account"
      onClick={() =>
        openBalance(code, {
          name,
          balance,
          number,
          currency,
          bankName,
          accountName,
          code,
          isSubaccount,
        })
      }
    >
      <div className="d-flex align-items-center gap-3">
        <img
          src={BanksIcons[bankName?.toLowerCase()] ?? logo}
          className="account-logo"
          alt={bankName}
        />

        <div style={{ display: 'grid' }}>
          <span className="account-name">
            {name}
            <span className="icon-slide">
              <ArrowCircleRight />
            </span>
          </span>

          <div className="d-flex align-items-center gap-1">
            <span className="account-number">{`${bankName} ${maskValue(number)}`}</span>
            <span className="position-relative" style={{ bottom: 1 }}>
              {getIcon(currency, 12)}
            </span>
          </div>
        </div>
      </div>

      <div className="account-balance d-flex gap-2 align-items-center">
        <CurrencyFormat
          prefix={getCurrency(currency)}
          value={balance / 100}
          displayType="text"
          isNumericString
          thousandSeparator={true}
          style={{ paddingTop: 4 }}
        />

        <CustomPopover
          zIndex="1"
          content={<Actions />}
          showPopover={showOptions}
          clickOutside={() => setShowOptions(false)}
        >
          <div onClick={() => setShowOptions(true)}>
            <DotsVertical />
          </div>
        </CustomPopover>
      </div>

      {/* DEPRECATED  */}
      <div className="d-none account-actions d-flex flex-wrap gap-1 align-items-center">
        <Tooltip
          placement="bottom"
          title={'View transfers'}
          key={'transfers'}
          color={'#000'}
        >
          <UnorderedListOutlined className="account-action-item"></UnorderedListOutlined>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={'See account details'}
          key={'details'}
          color={'#000'}
        >
          <BankFilled
            onClick={openDetailsModal}
            className="account-action-item"
          ></BankFilled>
        </Tooltip>

        <Tooltip
          placement="bottom"
          title={'Generate account statement'}
          key={'statement'}
          color={'#000'}
        >
          <FileDoneOutlined
            onClick={openStatementModal}
            className="account-action-item"
          ></FileDoneOutlined>
        </Tooltip>

        {isDirectDebit && (
          <Tooltip
            placement="bottom"
            title={`Direct debit is ${isDirectDebitActive ? 'active' : 'not active'}`}
            key={'isDirectDebit'}
            color={'#000'}
          >
            <span className="ms-auto account-badge">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <circle
                  cx="4.33008"
                  cy="4"
                  r="3"
                  fill={isDirectDebitActive ? '#12B76A' : '#D94F00'}
                />
              </svg>
              Direct debit
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const CurrencyBalance = ({ balance }) => {
  return (
    <>
      {Object.entries(balance)?.map(([key, value]) => (
        <div
          key={'account-summary' + key}
          className="d-flex align-items-center justify-content-between gap-2 font-bold mt-2"
          style={{
            backgroundColor: '#F5F5F4',
            padding: 20,
            width: 349,
            borderRadius: 10,
            height: 92,
            boxShadow: '0px 1px 2px 0px #1C19170D',
          }}
        >
          <div>
            <p
              style={{
                marginBottom: 0,
                color: '#79716B',
                fontWeight: 500,
                lineHeight: '20px',
                fontSize: '0.875rem',
              }}
            >
              {CURRENCY_TITLE[key]}
            </p>

            <FormattedCurrency
              value={value}
              prefix={getCurrency(key)}
              style={{
                fontSize: 24,
                color: '#1C1917',
                fontWeight: 600,
                lineHeight: '32px',
              }}
            />
          </div>

          <div className="balance-crest">{getIcon(key)}</div>
        </div>
      ))}
    </>
  );
};
