import { Col, Row } from 'react-bootstrap';
import FormattedCurrency from './FormattedCurrency';
import { getCurrency } from 'utils/helper';

const BudgetAnalytics = ({ data, loading }) => {
  const totalAmount = data?.['NGN']?.totalAvailable || 0;
  const totalSpent = data?.['NGN']?.totalSpent || 0;
  const totalBudgeted = data?.['NGN']?.totalBudgeted || 0;

  return (
    <section className="analytics-region mb-3">
      <Row>
        <Col sm={4}>
          <div className="card">
            <p className="caption">Total</p>
            <p className="value">
              <FormattedCurrency
                loading={loading}
                value={totalAmount}
                prefix={getCurrency('NGN')}
              />
            </p>
          </div>
        </Col>

        <Col sm={4}>
          <div className="card">
            <p className="caption">Spent</p>
            <p className="value">
              <FormattedCurrency
                loading={loading}
                value={totalSpent}
                prefix={getCurrency('NGN')}
              />
            </p>
          </div>
        </Col>

        <Col sm={4}>
          <div className="card">
            <p className="caption">Budgeted</p>
            <p className="value">
              <FormattedCurrency
                loading={loading}
                value={totalBudgeted}
                prefix={getCurrency('NGN')}
              />
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default BudgetAnalytics;
