import { useEffect, useState } from 'react';
import { components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/icons/unchecked.svg';

const InputOption = ({
  getStyles,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  selectProps,
  hasRadio,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const { setValue, closeMenu } = selectProps;

  // styles
  let bg = 'transparent';
  if (isFocused) bg = '#eee';
  if (isActive) bg = '#B2D4FF';

  const style = {
    alignItems: 'center',
    backgroundColor: bg,
    color: 'inherit',
    display: 'flex ',
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  const handleClick = () => {
    setValue(children); // Set the selected value
    closeMenu();
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <div
        onClick={handleClick}
        style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
      >
        {children}

        {hasRadio && <div>{isSelected ? <CheckIcon /> : <UnCheckedIcon />}</div>}
      </div>
    </components.Option>
  );
};

export const theStyle = (error, extra = {}) => {
  const style = {
    control: (base, state) => ({
      ...base,
      //   border: state.isFocused ? '#000' : base.color,
      // This line disable the blue border
      minHeight: '46px',
      display: 'flex',
      alignItems: 'center',
      padding: '3px 4px',

      // borderRadius: '6px',
      borderColor: state.isFocused ? '#000' : error ? 'rgb(220 38 38 /100)' : '#e5e5e5',
      ring: state.isFocused ? '#000' : '#000',
      color: state.isSelected ? '#000' : base.color,
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(208, 213, 221, .7)' : 0,
      '&:hover': {
        borderColor: state.isFocused ? '#000' : null,
        ring: state.isFocused ? '#000' : null,
      },

      '*': {
        boxShadow: 'none !important',
        borderColor: '#000',
      },

      ...extra?.control,
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#000' : '#6B7280',
      backgroundColor: state.isSelected ? '#F1F5F9' : base.color,
      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
      '&:hover': {
        color: '#6B7280',
        backgroundColor: '#F1F5F9',
      },
      ...extra?.option,
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: '#BDBDBD', // color for the cross
      '&:hover': {
        cursor: 'pointer',
        color: '#000000', // on hover color
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#BDBDBD',
        textAlign: 'start',
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
      };
    },
    singleValue: (base, state) => ({
      ...base,
      ...extra?.singleValue,
    }),
    multiValue: (base, state) => {
      return {
        ...base,
        backgroundColor: 'white',
        borderRadius: 6,
        border: '1px solid #D7D3D0',
      };
    },
    multiValueRemove: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      color: '#D7D3D0', // color for the remove indicator
      '&:hover': {
        color: '#000000', // on hover color
        cursor: 'pointer',
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: '8px',

      zIndex: 99999999, // Ensure dropdown is on top of other elements
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 99999999, // Adjust this value as necessary
    }),
  };

  return style;
};

export const CustomSelectRadio = ({
  placeholder,
  label,
  name,
  onChange,
  value,
  customStyles,
  hasRadio = true,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    } else setSelectedValue(null);
  }, [value]);

  const handleChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange(selectedOption);
  };

  return (
    <div className="w-100 groupWrapper form-group custom">
      {label && (
        <label htmlFor={name} className="mb-2 form-label">
          {label}
        </label>
      )}

      <AsyncPaginate
        {...props}
        name={name}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={handleChange}
        classNamePrefix="dropdown-select"
        value={selectedValue}
        components={{
          Option: (props) => (
            <InputOption
              hasRadio={hasRadio}
              {...props}
              selectProps={{
                setValue: setSelectedValue,
                closeMenu: props.selectProps.onMenuClose,
              }}
            />
          ),
        }}
        styles={theStyle(null, customStyles)}
        additional={{
          page: 1,
        }}
      />
    </div>
  );
};
