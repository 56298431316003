import { Checkbox, Skeleton } from 'antd';
import { default as classNames, default as cs } from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { useExpanded, usePagination, useRowSelect, useTable } from 'react-table';
import { cellType } from 'utils/helper';
import { ArrowLeft, ArrowRight } from '../../assets/icons';
import RenderComponent from './RenderComponent';
import './styles.scss';

export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox className="table-checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

const Table = ({
  columns,
  data,
  pagination,
  previous = false,
  currentPage = 1,
  totalPage,
  onRowClick,
  withTopBorder,
  type,
  hasMore,
  emptyPlaceholder,
  onEditClick,
  onDeleteClick,
  onDownloadClick,
  onShareClick,
  onCheckboxClick,
  onDisableClick,
  popoverAction,
  popoverState,
  setPopoverState,
  onRowSelect = null,
  onClick,
  modify,
  hasCheckBox = true,
  isCustomWidth = false,
  currentUser,
  nextPage,
  previousPage,
  getSelectedRows: GetSelectedRows,
  withoutBorder = false,
  setSelectedItems,
  stringType,
  customClass,
  loading = false,
  skeletonRows = 7, // specifies number of rows for table skeleton
  hasAvatar = true, // controls avatar loader for table skeleton
  reSelectRows = false,
  fetchDataSuccess = false,
  setReSelectRows = () => null,
  className,
  floatSelection = false,
  isFile = false,
  setIsFile,
  extendRow,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    pageOptions,
    setPageSize,
    selectedFlatRows,
    state,
    dispatch,
    // state: { selectedRow },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case 'toggleUnselectRow':
            return {
              ...newState,
              selectedRowIds: {},
            };

          default:
            return newState;
        }
      },
    },
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (hasCheckBox) {
          return [
            {
              id: 'selection',
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="ss">
                  <IndeterminateCheckbox
                    {...getToggleAllRowsSelectedProps({
                      onChange: (e) => {
                        handleSelectAll(e.target.checked);
                      },
                    })}
                  />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
              width: 30,
            },
            ...columns,
          ];
        } else {
          return columns;
        }
      });
    },
  );

  const {
    selectedTableRows: {
      selectedRows: selectedTableRows,
      shouldClearRows: shouldUnSelectRows,
    },
  } = useSelector(({ table }) => table);

  const [selectedId, setSelectedId] = useState('');
  const [kebabId, setKebabId] = useState('');

  const handleClear = () => {
    dispatch({ type: 'toggleUnselectRow' });
  };

  const handleCheckall = () => {
    // toggleAllRowsSelected();
    dispatch({ type: 'toggleAllRowsSelected' });
  };

  useEffect(() => {
    if (popoverState) {
      setSelectedId('');
      setKebabId('');
      setPopoverState(false);
    }
  }, [popoverState]);

  const noRowsSelected = selectedFlatRows.length === 0;

  useEffect(() => {
    if (fetchDataSuccess) {
      toggleSpecificRows(selectedTableRows?.map((item) => item.code) || []);
      setReSelectRows(false);
    }
  }, [fetchDataSuccess]);

  useEffect(() => {
    if (onRowSelect !== null && selectedFlatRows.length > 0) {
      const selectedRows = selectedFlatRows.map((item) => item.original);
      onRowSelect(selectedRows);
    }
  }, [selectedFlatRows]);

  useEffect(() => {
    if (onRowSelect !== null && noRowsSelected && !reSelectRows) {
      onRowSelect([]);
    }
  }, [noRowsSelected]);

  useEffect(() => {
    if (shouldUnSelectRows) {
      handleClear();
    }
  }, [shouldUnSelectRows]);

  const toggleSpecificRows = (rowIds) => {
    rows.forEach((row) => {
      if (rowIds.includes(row.original.code)) {
        row.toggleRowSelected(true);
      }
    });
  };

  // useMemo(() => setSelectedItems(selectedFlatRows), [selectedFlatRows]);

  const handleSelectAll = (isSelected) => {
    // Determine the selectable rows (those not disabled)
    const selectableRows = rows.filter(({ original }) => !original.checkBoxDisabled);

    // Toggle selection state based on current state
    selectableRows.forEach((row) => row.toggleRowSelected(isSelected));
  };

  const getColumnStyle = (column, index) => {
    if (isCustomWidth) {
      return { width: `${column.width}px` };
    }

    if (hasCheckBox && index === 0) {
      return { width: 0 };
    }

    return undefined;
  };

  const displayCell = (row, cell, index) => {
    return (
      <RenderComponent
        onDeleteClick={() => (onDeleteClick ? onDeleteClick(row.original) : null)}
        customClass={() => (customClass ? customClass(row.original) : null)}
        onEditClick={() => (onEditClick ? onEditClick(row.original) : null)}
        onDownloadClick={() => (onDownloadClick ? onDownloadClick(row.original) : null)}
        onShareClick={() => (onShareClick ? onShareClick(row.original) : null)}
        onCheckboxClick={() => (onCheckboxClick ? onCheckboxClick(row.original) : null)}
        onDisableClick={() => (onDisableClick ? onDisableClick(row.original) : null)}
        onClick={() => (onClick ? onClick(row.original) : null)}
        popoverAction={popoverAction}
        type={type}
        modify={modify}
        column={cell.column}
        row={cell.row.original}
        value={cell.value}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
        setKebabId={setKebabId}
        kebabId={kebabId}
        index={index}
        stringType={stringType}
      />
    );
  };

  if (!data.length && !loading && emptyPlaceholder) {
    return (
      <div className="table-nodata">
        <span>{emptyPlaceholder}</span>
      </div>
    );
  }

  return (
    <>
      <div
        className={cs(
          {
            'table-wrapper': 1,
            cursor: 1,
            ['table-wrapper__border']: !withoutBorder,
          },
          { [className]: className },
        )}
      >
        <BTable size="large" className="p-0 m-0" responsive {...getTableProps()}>
          <thead className={cs({ ['top-table-border']: withTopBorder })}>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    key={i}
                    style={getColumnStyle(column, i)}
                  >
                    <span
                      className={cs({
                        ['d-flex justify-content-center']:
                          headerGroup.headers.length - 1 === i,
                      })}
                    >
                      {column.render('Header')}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="border-top-0" {...getTableBodyProps()}>
            {loading
              ? Array.from({ length: skeletonRows }).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {columns.map((_, i) => (
                      <td
                        key={i}
                        className={
                          i === 0 && hasAvatar ? 'd-flex gap-2 align-items-center' : ''
                        }
                      >
                        {i === 0 && hasAvatar && <Skeleton.Avatar active size={36} />}
                        <Skeleton.Input
                          active
                          className={`${hasAvatar && i == 0 ? '' : 'mt-1'} ${
                            i === columns.length - 1
                              ? 'd-flex justify-content-center'
                              : ''
                          }`}
                          style={{
                            borderRadius: 4,
                            height: i === columns.length - 1 ? 14 : 12,
                            width: i === columns.length - 1 ? '10px' : '100px',
                            minWidth: i === columns.length - 1 ? '10px' : '100px',
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ))
              : rows.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={rowIndex}
                      {...row.getRowProps()}
                      onClick={(event) => {
                        if (
                          event.target.type === 'checkbox' ||
                          event.target.tagName === 'svg'
                        )
                          return;

                        onRowClick && !row.original.notClickable
                          ? onRowClick(row.original)
                          : null;
                      }}
                      className={classNames('align-table table-row', {
                        ['bg-danger bg-opacity-25']: row.original?.error,
                      })}
                    >
                      {row.original.createButton ? (
                        <td {...row.cells?.[0]?.getCellProps()} colSpan={columns.length}>
                          {row.cells?.[0]?.render('Cell')}
                        </td>
                      ) : (
                        <>
                          {row.cells.map((cell, i) => {
                            return (
                              <td {...cell.getCellProps()} key={i}>
                                {[cellType.SELECTION, cellType.EXPANDER].includes(
                                  cell.column.type,
                                ) ? (
                                  cell.render('Cell')
                                ) : [undefined].includes(cell.column.type) ? (
                                  <div>
                                    <IndeterminateCheckbox
                                      disabled={row.original.checkBoxDisabled}
                                      {...row.getToggleRowSelectedProps()}
                                    />
                                  </div>
                                ) : (
                                  displayCell(row, cell, rowIndex)
                                )}
                              </td>
                            );
                          })}
                        </>
                      )}
                    </tr>
                  );
                })}

            {extendRow}
          </tbody>
        </BTable>

        {pagination && (
          <div className="pagination-wrapper">
            <div className="pagination-actions">
              {Number(currentPage) !== 1 && (
                <button className="tab-hover-effect" onClick={previousPage}>
                  <ArrowLeft />
                  <span>Previous</span>
                </button>
              )}
            </div>
            <div className="pagination-number">
              <span>
                Page {currentPage} of {totalPage || 1}
              </span>
            </div>
            <div className="pagination-actions">
              {Number(currentPage) !== Number(totalPage) && (
                <button onClick={nextPage} className="tab-hover-effect">
                  <span>Next</span>
                  <ArrowRight />
                </button>
              )}
            </div>
          </div>
        )}

        {GetSelectedRows && !floatSelection && (
          <GetSelectedRows
            rows={selectedFlatRows.map((item) => item.original)}
            unSetRows={handleClear}
            selectAllRow={handleCheckall}
            isFile={isFile}
            tableData={data}
            setIsFile={setIsFile}
          />
        )}
      </div>
      {GetSelectedRows && floatSelection && (
        <GetSelectedRows
          rows={selectedFlatRows.map((item) => item.original)}
          unSetRows={handleClear}
          selectAllRow={handleCheckall}
          isFile={isFile}
          tableData={data}
          setIsFile={setIsFile}
        />
      )}
    </>
  );
};
export default Table;
