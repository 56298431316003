import classNames from 'classnames';
import AccountName from 'components/UI/AccountName';
import CustomInput from 'components/UI/CustomInput';
import CustomSelect from 'components/UI/CustomSelect';
import { toastError } from 'components/UI/toast';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addBeneficiaryBank } from 'redux/actions/BeneficiariesAction';
import { getAllBanks, verifyBankAccount } from 'redux/actions/PaymentAction';

const UpdateBankAccount = () => {
  const dispatch = useDispatch();

  const {
    addBeneficiaryBank: { loading },
    getBeneficiaryBank: { data = [] },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const {
    verifyBankAccount: {
      data: accName,
      loading: accountNameLoading,
      success: accountNameSuccess,
      error: accountNameError,
    },
    getAllBanks: {
      data: banksData,
      loading: loadingBanks,
      success: successBanks,
      error: errorBanks,
    },
  } = useSelector(({ payments }) => payments);

  const [bankValue, setBankValue] = useState('');
  const bankValuedebounced = useDebounce(bankValue, 200);

  const handleGetBankOnChange = (val) => setBankValue(val);

  const mappedBanks = banksData?.map((item) => item.name);

  const allBanks = useMemo(() => {
    return banksData?.map((item) => ({
      label: item.label,
      value: item.bankCode,
    }));
  }, [successBanks, errorBanks, mappedBanks]);

  useEffect(() => {
    const banks = allBanks?.find((option) =>
      option?.label?.toLowerCase().includes(bankValuedebounced?.toLowerCase()),
    );
    if (!banks && bankValuedebounced) {
      dispatch(getAllBanks({ search: bankValuedebounced?.toLowerCase() }));
    }
  }, [bankValuedebounced]);

  const onMenuCloseBanks = () => {
    if (bankValuedebounced) dispatch(getAllBanks());
  };

  const [kycBank, setKycBank] = useState({
    bankName: '',
    accountName: '',
    accountNumber: '',
  });
  const [isAccountSet, setIsAccountSet] = useState(false);

  useEffect(() => {
    if (!banksData) dispatch(getAllBanks());
  }, []);

  const getUserBank = data?.find((item) => item.ownerType === 'user');

  useEffect(() => {
    if (getUserBank) {
      const bankName = allBanks?.find((val) => val.value === getUserBank?.bankCode);

      setKycBank({
        ...kycBank,
        bankName: bankName,
        accountName: getUserBank?.accountName,
        accountNumber: getUserBank?.number,
      });
    }
  }, [getUserBank]);

  const handleSubmit = () => {
    if (!getUserBank?.accountName) {
      if (!kycBank.bankName) return toastError('Please select a bank');
      if (!kycBank.accountName) return toastError('Please enter your account name');
      if (!kycBank.accountNumber) return toastError('Please enter your account number');

      const payload = {
        bankCode: kycBank.bankName.value,
        bankName: kycBank.bankName.label,
        accountName: kycBank.accountName,
        number: kycBank.accountNumber,
      };
      dispatch(addBeneficiaryBank(payload));
    }
  };

  const handleChange = ({ target: { name, value, validity } }) => {
    if (['accountName', 'accountNumber'].includes(name))
      return validity.valid && setKycBank({ ...kycBank, [name]: value });
    return setKycBank({ ...kycBank, [name]: value });
  };

  useEffect(() => {
    if (kycBank.accountNumber.length === 10 && kycBank.bankName.value && !getUserBank) {
      const { accountNumber, bankName } = kycBank;
      dispatch(verifyBankAccount({ accountNumber, bankCode: bankName.value }));
    }
    if (kycBank.accountNumber.length < 10 && !getUserBank) {
      setIsAccountSet(false);
      setKycBank({ ...kycBank, accountName: undefined });
    }
  }, [kycBank.accountNumber, kycBank.bankName.value]);

  useEffect(() => {
    if (accountNameSuccess) {
      setKycBank({ ...kycBank, accountName: accName.account_name });
      setIsAccountSet(true);
    }
    if (accountNameError) setKycBank({ ...kycBank, accountName: undefined });
    if (accountNameLoading) setKycBank({ ...kycBank, accountName: undefined });
  }, [accountNameSuccess, accountNameError, accountNameLoading]);

  const visible = accountNameLoading || accountNameError;

  return (
    <Row className="w-100 p-0 m-0">
      <Col className="p-0 m-0">
        <Col className="border py-2 rounded-3 mt-3">
          <div className="px-3">
            <Row className="mb-3 mt-3">
              <CustomSelect
                label="Bank"
                name="bankName"
                placeholder="Select Bank"
                onChange={(val) => setKycBank({ ...kycBank, bankName: val })}
                value={kycBank.bankName}
                options={allBanks}
                onMenuClose={onMenuCloseBanks}
                onInputChange={handleGetBankOnChange}
                isDisabled={(loadingBanks && !bankValuedebounced) || !!getUserBank}
                isLoading={loadingBanks && !bankValuedebounced}
              />
            </Row>

            <Row className="mb-3">
              <div
                className={classNames('position-relative w-100', { ['mb-5']: visible })}
              >
                <CustomInput
                  type="text"
                  label="Account number"
                  placeholder="Enter number"
                  name="accountNumber"
                  onChange={handleChange}
                  value={kycBank.accountNumber}
                  maxLength="10"
                  pattern="[0-9]*"
                  disabled={!!getUserBank}
                />
                {!getUserBank && (
                  <AccountName
                    setValue={(value) => setKycBank({ ...kycBank, accountName: value })}
                    visible={visible}
                    accountNameError={accountNameError}
                    accountNameLoading={accountNameLoading}
                  />
                )}
              </div>
            </Row>

            <Row className="mb-3">
              <CustomInput
                type="text"
                label="Account name"
                placeholder="Add account name"
                name="accountName"
                onChange={handleChange}
                value={kycBank.accountName}
                maxLength="50"
                pattern="[a-zA-Z -]*"
                disabled
              />
            </Row>
          </div>
          <div className="border-top button-container w-100 px-3 pt-3 me-md-auto pb-2 d-md-flex align-items-center gap-3">
            <button type="button" className="btn border sm ms-md-auto mb-md-0 mb-2">
              Cancel
            </button>
            <button
              type="button"
              disabled={loading || !isAccountSet || !!getUserBank}
              onClick={handleSubmit}
              className="btn black sm"
            >
              Save and submit
            </button>
          </div>
        </Col>
      </Col>
    </Row>
  );
};

export default UpdateBankAccount;
