/* eslint-disable no-console */
import { ArrowRight, HintAlertIcon, ShieldAlertIcon } from 'assets/icons';
import Vslide from 'assets/images/slider/v_slider.png';
import vendorCompletedIcon from 'assets/images/vendor-completed.png';
import classNames from 'classnames';
import { AuthFormHeader } from 'components/Auth';
import CustomButton from 'components/UI/CustomButton';
import CustomDatePicker from 'components/UI/CustomDatePicker';
import CustomInput from 'components/UI/CustomInput';
import CustomPhoneNumberInput from 'components/UI/CustomPhoneNumberInput';
import CustomSelect from 'components/UI/CustomSelect';
import FileUpload from 'components/UI/FileUpload';
import { toastError } from 'components/UI/toast';
import { addYears } from 'date-fns';
import dayjs from 'dayjs';
import Layout, { OverflowWrapLayout } from 'pages/Authentication/Layout';
import { useEffect, useMemo, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getCountries,
  getSingleInvitedDirector,
  getState,
  uploadInvitedDirectorInfo,
} from 'redux/actions/CompaniesAction';
import { idType, removeEmptyString, validateNigeriaNumber } from 'utils/helper';
import {
  encryptAndSaveToLocalStorage,
  retrieveAndDecryptFromLocalStorage,
} from 'utils/utility';
import { Skeleton } from 'antd';
import { sliderContents } from 'pages/Authentication/config';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';

const contents = [
  {
    title: 'Impactful title',
    desc: 'Powerful, self-serve product and growth analytics to help you convert.',
    imgSrc: Vslide,
  },
  {
    title: 'Another Title',
    desc: 'Additional description goes here. Make sure to tailor it to your audience.',
    imgSrc: Vslide,
  },
  {
    title: 'Final Slide',
    desc: 'Your last piece of information should be impactful and memorable.',
    imgSrc: Vslide,
  },
];

const InvitedDirectorForm = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const { push, replace } = useHistory();
  const location = useLocation();
  const [steps, setSteps] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [businessOwner, setBusinessOwner] = useState({});
  const [businessOwnerDocument, setBusinessOwnerDocument] = useState({});

  const [menus, setMenus] = useState([
    { name: 'Personal details', isActive: true, completed: false },
    { name: 'Contact information', isActive: false, completed: false },
    { name: 'Identity verification', isActive: false, completed: false },
  ]);

  const {
    getCountry: { data: countryData = {}, loading: loadingCountry },
    getState: { data: states = {}, success: successState, loading: loadingState },
  } = useSelector(({ companies }) => companies);

  const {
    uploadInvitedDirectorInfo: { success, loading },
    getSingleInvitedDirector: { data, loading: loadsingleInvite },
  } = useSelector(({ companies }) => companies);

  useEffect(() => {
    if (!countryData?.length) dispatch(getCountries());
  }, []);

  const generateCountry = useMemo(() => {
    if (countryData?.length > 0) {
      return countryData?.map(({ code: value, name: label }) => ({
        value,
        label,
        isDisabled: label !== 'NIGERIA' ? true : false,
      }));
    }
  }, [countryData]);

  const generateState = useMemo(() => {
    return states.states?.map((value) => ({
      label: value,
      value: value,
    }));
  }, [successState]);

  const getCountryStates = async (val) => {
    dispatch(getState(val.value));
  };

  useEffect(() => {
    if (data?.code)
      setBusinessOwner({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
      });
  }, [data?.code]);

  const handlePhoneNumberChange = (localFormat, internationalFormat, countryCode) => {
    setBusinessOwner({
      ...businessOwner,
      internationalFormat,
      localFormat,
      countryCode,
    });
  };

  const handleInputChange = ({ target: { name, value, validity } }) => {
    if (['bvn'].includes(name))
      return validity.valid && setBusinessOwner({ ...businessOwner, [name]: value });
    return setBusinessOwner({ ...businessOwner, [name]: value });
  };

  const handleChangeDate = (value) => {
    setBusinessOwner({
      ...businessOwner,
      dob: dayjs(value).format('YYYY-MM-DD'),
    });
  };

  const handleSubmit = () => {
    if (steps === 0) {
      if (!businessOwner.percentageOwned)
        return toastError('Please enter percentage owned');
      if (+businessOwner.percentageOwned < 5)
        return toastError('Percentage owned must be at least 5%');
      if (!businessOwner.firstName) return toastError('Please enter first name');
      if (!businessOwner.lastName) return toastError('Please enter last name');
      if (!businessOwner.dob) return toastError('Please enter date of birth');
      if (!businessOwner.bvn) return toastError('Please enter BVN');

      const payload = {
        code,
        firstName: businessOwner.firstName,
        lastName: businessOwner.lastName,
        dob: businessOwner.dob,
        bvn: businessOwner.bvn,
        percentageOwned: businessOwner.percentageOwned,
      };

      const payloads = removeEmptyString(payload);
      return dispatch(uploadInvitedDirectorInfo(payloads));
    }

    if (steps === 1) {
      if (!businessOwner.email) return toastError('Please enter email');
      if (
        !isValidPhoneNumber(String(businessOwner?.internationalFormat)) ||
        !validateNigeriaNumber(String(businessOwner?.internationalFormat))
      )
        return toastError('Please enter a valid phone number');

      if (!businessOwner.country) return toastError('Please select country');
      if (!businessOwner.state) return toastError('Please select state');
      if (!businessOwner.address) return toastError('Please enter address');
      if (!businessOwnerDocument.utilityBill)
        return toastError('Please upload a copy of the proof of address');

      const payload = {
        code,
        phoneNumber: {
          countryCode: businessOwner?.countryCode,
          localFormat: businessOwner?.localFormat,
        },
        address: {
          country: businessOwner?.country?.value,
          state: businessOwner?.state?.value,
          city: businessOwner?.city,
          street: businessOwner?.address,
        },
        documents: {
          utilityBill: businessOwnerDocument.proofOfAddress,
        },
      };

      const payloads = removeEmptyString(payload);
      return dispatch(uploadInvitedDirectorInfo(payloads));
    }

    if (steps === 2) {
      if (!businessOwner.idType) return toastError('Please select ID type');
      if (!businessOwner.idNumber)
        return toastError(`Please enter ${businessOwner.idType.value} number`);
      if (
        (businessOwner.idNumber.length < 11 || businessOwner.idNumber.length > 11) &&
        businessOwner.idType.value === 'nin'
      )
        return toastError('ID number must be 11 digit');

      if (!businessOwnerDocument.idCopy)
        return toastError('Please upload a copy of the ID');

      const payload = {
        code,
        documents: {
          idType: businessOwner.idType.value,
          idNumber: businessOwner.number,
          idCopy: businessOwnerDocument.idCopy,
        },
      };

      const payloads = removeEmptyString(payload);
      return dispatch(uploadInvitedDirectorInfo(payloads));
    }
  };

  const updateMenus = (val) => {
    const updateMenu = menus?.map((menu, index) => {
      return {
        ...menu,
        isActive: index === val,
        completed: index < val,
      };
    });
    setMenus(updateMenu);
  };

  const nextScreen = (step) => {
    setSteps(step);
    updateMenus(step);
  };

  const goback = () => {
    setSteps((prev) => {
      updateMenus(prev - 1);
      return prev - 1;
    });
  };

  const closePage = () => {
    // history.push('/');
  };

  useEffect(() => {
    if (code) {
      encryptAndSaveToLocalStorage('director-app-session', code);
      localStorage.removeItem('exp-app-session');
      localStorage.removeItem('app-session');
      localStorage.removeItem('vendor-app-session');
      // localStorage.clear();
      if (!completed) dispatch(getSingleInvitedDirector(code));
    }
  }, [code]);

  useEffect(() => {
    if (success) {
      if (steps < 2) nextScreen(steps + 1);
      else {
        setCompleted(true);
        setTimeout(() => {
          localStorage.removeItem('vendor-app-session');
          window.open('https://www.bujeti.com/', '_self');
        }, 5000);
      }
    }
  }, [success]);

  const StepOneForm = (
    <>
      {loadsingleInvite ? (
        // Loading Skeleton
        <>
          <div className="border-bottom bg-transparent pb-3">
            <Row className="mb-2">
              <Skeleton.Input active className="rounded-2 w-100" size={42} />
            </Row>
          </div>

          <div className="pt-2 bg-transparent pb-3">
            <Row className="mb-3 mt-3 grid grid-col-2 gap-2 align-items-center">
              <Skeleton.Input active className="rounded-2 w-100" size={42} />

              <Skeleton.Input active className="rounded-2 w-100" size={42} />
            </Row>

            <Row className="mb-3">
              <Skeleton.Input active className="rounded-2 w-100" size={42} />
            </Row>

            <Row className="mb-3">
              <Skeleton.Input active className="rounded-2 w-100" size={42} />

              <Skeleton.Input active className="rounded-2 w-100 mt-2" size={22} />
            </Row>
          </div>
          <Skeleton.Input active className="rounded-2 w-100" size={52} />
        </>
      ) : (
        <>
          <div className="border-bottom bg-transparent pb-3">
            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Percentage owned"
                placeholder="Ex. 10%"
                name="percentageOwned"
                onChange={handleInputChange}
                value={businessOwner.percentageOwned}
                isNumber={false}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="3"
              />
            </Row>
          </div>

          <div className="pt-2 bg-transparent pb-3">
            <Row className="mb-2 mt-2 align-items-center">
              <CustomInput
                type="text"
                label="First name"
                placeholder="Enter first name"
                name="firstName"
                onChange={handleInputChange}
                value={businessOwner.firstName}
                md={6}
              />

              <CustomInput
                type="text"
                label="Last name"
                placeholder="Enter last name"
                name="lastName"
                onChange={handleInputChange}
                value={businessOwner.lastName}
                md={6}
              />
            </Row>

            <Row className="mb-2">
              <CustomDatePicker
                label="Date of birth"
                name="dob"
                onChange={handleChangeDate}
                maxDate={addYears(new Date(), -18)}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText="DD / MM / YYYY"
                selected={businessOwner.dob && dayjs(businessOwner.dob).toDate()}
              />
            </Row>

            <Row className="mb-2">
              <CustomInput
                type="text"
                label="Bank Verification Number (BVN)"
                placeholder="11234567890"
                name="bvn"
                onChange={handleInputChange}
                value={businessOwner.bvn}
                maxLength="11"
                pattern="[0-9]*"
              />
              <Form.Text className="text-muted gap-1 d-flex align-items-center">
                <ShieldAlertIcon />
                <span className="text-xs">
                  Your BVN is used for identify verification and secured with encryption.
                </span>
              </Form.Text>
            </Row>
          </div>
          <CustomButton
            type="button"
            fullWidth
            className="mt-3"
            onClick={handleSubmit}
            loading={loading}
            disabled={loading}
          >
            Continue <ArrowRight className="ms-1" color="#fff" />
          </CustomButton>
        </>
      )}
    </>
  );

  const StepTwoForm = (
    <>
      <div className="bg-transparent pb-3">
        <Row className="mb-2 mt-2">
          <CustomPhoneNumberInput
            label="Phone number"
            placeholder="Enter your mobile number"
            onChange={(localFormat, international, countryCode) =>
              handlePhoneNumberChange(localFormat, international, countryCode)
            }
            value={businessOwner.internationalFormat}
          />
        </Row>

        <Row className="mb-2">
          <CustomInput
            type="text"
            label="Enter your email"
            placeholder="joe@mail.com"
            name="email"
            disabled={!!data?.email}
            onChange={handleInputChange}
            value={businessOwner.email}
          />
        </Row>

        <Row className="mb-2">
          <CustomSelect
            label="Country"
            name="country"
            placeholder="Select country"
            onChange={(value) => {
              setBusinessOwner({ ...businessOwner, country: value });
              getCountryStates(value);
            }}
            value={businessOwner.country}
            options={generateCountry}
            isLoading={loadingCountry}
          />
        </Row>
        <Row className="mb-2">
          <CustomSelect
            label="State"
            name="state"
            placeholder="Select country"
            onChange={(value) => {
              setBusinessOwner({ ...businessOwner, state: value });
            }}
            value={businessOwner.state}
            options={generateState}
            isLoading={loadingState}
          />
        </Row>

        <Row className="mb-2">
          <CustomInput
            label="City"
            name="city"
            placeholder="Enter city"
            onChange={handleInputChange}
            value={businessOwner.city}
          />
        </Row>

        <Row className="mb-2">
          <CustomInput
            label="Home address"
            name="address"
            placeholder="Enter home address"
            onChange={handleInputChange}
            value={businessOwner.address}
          />
        </Row>

        <Row className="mb-2">
          <ServerFileUpload
            label="Proof of Address"
            supportType="JPEG, PNG, and PDF formats, up to 5 MB."
            uploadedFile={businessOwnerDocument.utilityBill}
            onChange={({ assetCode } = {}) =>
              setBusinessOwnerDocument({
                ...businessOwnerDocument,
                utilityBill: assetCode,
              })
            }
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            name={`owner-proof-address`}
          />
          <Form.Text className="text-muted gap-1 d-flex align-items-start flex-column">
            <div className="p-0 m-0 d-flex gap-1 align-items-start pb-2 text-xs">
              <div>
                <HintAlertIcon />
              </div>
              Upload a copy of your bank statement, utility bill, phone bill, tax
              assessment, or any government-issued document.
            </div>

            <ul className="py-0 my-0 text-xs">
              <li> All documents must be less than 3 months old</li>
              <li>And must include the name of the identified individual or business.</li>
            </ul>
          </Form.Text>
        </Row>
      </div>
      <CustomButton
        type="button"
        fullWidth
        className="mt-3"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
      >
        Continue <ArrowRight className="ms-1" color="#fff" />
      </CustomButton>
    </>
  );

  const StepThreeForm = (
    <>
      <div className="pb-3">
        <Row className="mb-2 mt-2">
          <CustomSelect
            label="Document type"
            name="idType"
            placeholder="Select document type"
            onChange={(value) => setBusinessOwner({ ...businessOwner, idType: value })}
            value={businessOwner.idType}
            options={idType}
          />
        </Row>

        <Row className="mb-2">
          <CustomInput
            type="text"
            label="ID number"
            placeholder="11234567890"
            name="number"
            onChange={handleInputChange}
            value={businessOwner.number}
          />
        </Row>

        <Row className="mb-2">
          <ServerFileUpload
            label="Upload ID"
            supportType="JPEG, PNG, and PDF formats, up to 5 MB."
            uploadedFile={businessOwnerDocument.utilityBill}
            onChange={({ assetCode } = {}) =>
              setBusinessOwnerDocument({
                ...businessOwnerDocument,
                idCopy: assetCode,
              })
            }
            setUploadingFile={setUploadingFile}
            uploadingFile={uploadingFile}
            name={`owner-doc-id`}
          />
        </Row>
      </div>
      <CustomButton
        type="button"
        fullWidth
        className="mt-3"
        onClick={handleSubmit}
        loading={loading}
        disabled={loading}
      >
        Submit <ArrowRight className="ms-1" color="#fff" />
      </CustomButton>
    </>
  );

  const completedView = (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column text-center  justify-content-center align-items-center">
        <img src={vendorCompletedIcon} alt="completed" />
        <div className="completed-text mt-4">
          <h1>Thank you!</h1>
          <p>
            Your information has been submitted successfully and a mail has been sent to
            the admin.
          </p>
        </div>
      </div>
    </div>
  );

  const renderScreen = {
    [0]: StepOneForm,
    [1]: StepTwoForm,
    [2]: StepThreeForm,
  };

  const title = 'Provide business owner details';
  const subtitle = 'Help us verify your ownership stake for compliance purposes.';
  return (
    <Layout
      menus={menus}
      slider={sliderContents}
      hideSlider={completed}
      hideSteps={completed}
    >
      <AuthFormHeader
        backButton={!completed && steps > 0}
        goBack={goback}
        title={completed ? null : title}
        subtitle={completed ? null : subtitle}
      />
      <OverflowWrapLayout
        height={completed ? '100%' : undefined}
        maxWidth={completed ? '580px' : undefined}
      >
        <div className={classNames('w-100', { ['h-100']: completed })}>
          {completed ? completedView : renderScreen[steps]}
        </div>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default InvitedDirectorForm;
