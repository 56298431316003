import { ReactComponent as ApprovedTransaction } from 'assets/icons/budget-analytics/approved-transactions.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/budget-analytics/chevron-right.svg';
import { ReactComponent as Invoices } from 'assets/icons/budget-analytics/invoices.svg';
import { ReactComponent as PendingCards } from 'assets/icons/budget-analytics/pending-cards.svg';
import { ReactComponent as PendingRequest } from 'assets/icons/budget-analytics/pending-requests.svg';
import classNames from 'classnames';
import OverviewCard from 'components/OverviewCards';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AreaChartView from '../../../components/charts/AreaChart';
import SubHeader from './components/SubHeader';
import TotalAmount from './components/TotalAmount';
import Balances from './components/Balances';
import OverviewLoader from './Loader';

import ImgCard from 'components/UI/ImgCard';

import { Skeleton, Tooltip } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import {
  getAccountReauthorizationCode,
  getCompanyStats,
  getPaymentLink,
  sendMonoAuthCode,
  verifyPaymentReference,
} from 'redux/actions/CompaniesAction';

import { getPayins, getPayouts } from 'redux/actions/ReportsAction';

import { getBalances } from 'redux/actions/BudgetsAction';
import { RESET_BLOCK_DATE_RANGE } from 'redux/reducers/OverviewReducer';
import { allPermissions } from 'utils/AllowedTo';
import { buildChartDataFromTransaction, getCurrency, getPercentage } from 'utils/helper';
import { getFromAndTo, numFormatter } from 'utils/utility';
import FundsModal from './FundsModal';
import StatementModal from './StatementModal';

import { ArrowRight } from 'assets/icons';

import DateRangeFilter from 'components/DateRangeFilter';
import SwapModal from 'pages/Cash/Overview/components/SwapModal';

const Overview = ({ company, isLoading, accounts }) => {
  // TransactionsAction top header
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggleBalance, setToggleBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insideModalClick, setInsideModalClick] = useState(false);
  const [openSwapModal, setOpenSwapModal] = useState(false);
  const [monoAuthCode, setMonoAuthCode] = useState(null);
  const [fundStep, setFundStep] = useState(1);
  const [reauthorizationCode, setReauthorizationCode] = useState(null);
  const [statementModal, setStatementModal] = useState(false);
  const seeBreakDown = () => history.push('/accounts');
  const closeHandler = () => {
    if (!insideModalClick) setIsModalOpen(false);
    setFundStep(1);
    history.push('/');
    setPaymentFailed(false);
    setPaymentVerified(false);
  };
  const location = useLocation();
  const [searchParams] = useState(new URLSearchParams(location.search));

  const {
    getStatistics: { data, loading },
    getPaymentLink: { data: dataPaymentLink, loading: loadingPaymentLink },
    verifyPaymentReference: {
      data: verifyPaymentReferenceData,
      loading: verifyingPayment,
    },
    getAccountReauthorizationCode: {
      data: reauthorizationCodeData,
      loading: fetchingReauthenticationCode,
    },
  } = useSelector(({ companies }) => companies);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const {
    getPayinReport: { data: { moneyIn: payin = [] } = {}, loading: payinLoading },
    getPayoutReport: { data: { moneyOut: payout = [] } = {}, loading: payoutLoading },
  } = useSelector(({ reports }) => reports);

  const {
    user: {
      data: {
        user: { firstName, lastName, company: { code: companyCode } = {} } = {},
      } = {},
    },
  } = useSelector(({ auth }) => auth);

  const { isAdmin } = allPermissions();

  // const handleToggleBalance = () => {
  //   if (toggleBalance === 0) return setToggleBalance(1);
  //   return setToggleBalance(0);
  // };
  const transactions = data?.recentTransactions || [];
  const defaultCurrency = toggleBalance === 0 ? 'NGN' : 'USD';
  const balanceName = toggleBalance === 0 ? 'Naira' : 'Dollar';

  const { selectedDateRange, selectedDateRangeMoneyout } = useSelector(
    ({ overview }) => overview,
  );

  const [selectedRange, setSelectedRange] = useState('Today');
  const [selectedRangeMoneyOut, setSelectedRangeMoneyOut] = useState('Today');

  useEffect(() => {
    dispatch(getBalances({ includeCardBalances: 1 }));
  }, []);

  useEffect(() => {
    if (company?.onboardingStatus === 'approved')
      dispatch(getCompanyStats({ id: company.code }));
  }, [company.code]);

  useEffect(() => {
    setSelectedRange(selectedDateRange?.val);
  }, [selectedDateRange.range]);

  useEffect(() => {
    setSelectedRangeMoneyOut(selectedDateRangeMoneyout?.val);
  }, [selectedDateRangeMoneyout.range]);

  const [overviewTotal, setOverviewTotal] = useState({
    totalDisbursed: {
      amount: 0,
      percentage: 0,
    },
    totalBalance: {
      amount: 0,
      percentage: 0,
    },
    topSpenders: {
      [defaultCurrency]: [
        {
          amount: 0,
          percentage: 0,
          firstName: '',
        },
      ],
    },
  });
  const [chartData, setChartData] = useState({
    chart: [],
    totalSpent: 0,
    totalPercentage: 0,
  });

  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [dateRangeMoneyOut, setDateRangeMoneyOut] = useState({ from: null, to: null });

  const handleSwapping = () => {
    setOpenSwapModal((prevValue) => !prevValue);
  };

  const handleRange = (val) => {
    switch (val) {
      case 1:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
      case 2:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
      case 3:
        setChartData({
          chart: [],
          totalSpent: 0,
          totalPercentage: 0,
        });
        setOverviewTotal({
          totalDisbursed: { amount: 0, percentage: 0 },
          totalBalance: { amount: 0, percentage: 0 },
          topSpenders: [],
        });
        return;
    }
  };

  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
    const { startDate, endDate } = selectedDateRange?.range[0];

    setDateRange({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRange]);

  useEffect(() => {
    handleRange(Math.floor(Math.random() * 3) + 1);
    const { startDate, endDate } = selectedDateRangeMoneyout?.range[0];

    setDateRangeMoneyOut({
      from: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
      to: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
    });
  }, [selectedDateRangeMoneyout]);

  const aliaseCount = (count) => {
    if (count < 0) return 0;
    return count > 99 ? '99+' : count;
  };

  let pendingTasks = [
    // pending transactions
    data?.pendingAmountsAndCounts?.transactions?.totalCount && {
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.transactions?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.transactions?.totalCount),
      title: `Pending Transaction${
        data?.pendingAmountsAndCounts?.transactions?.totalCount > 1 ? 's' : ''
      } `,
      link: '/transactions/requests',
    },

    // fund requests
    data?.pendingAmountsAndCounts?.fundRequests?.totalCount && {
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.fundRequests?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.fundRequests?.totalCount),
      title: ` Fund request${
        data?.pendingAmountsAndCounts?.fundRequests?.totalCount > 1 ? 's' : ''
      }`,
      link: '/requests/funds?status=pending',
    },

    // reimbursements
    data?.pendingAmountsAndCounts?.reimbursements?.totalCount && {
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.reimbursements?.[defaultCurrency]?.amount /
              100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.reimbursements?.totalCount),
      title: ` Reimbursement request${
        data?.pendingAmountsAndCounts?.reimbursements?.totalCount > 1 ? 's' : ''
      }`,
      link: '/requests/reimbursements?status=pending',
    },

    // overdue invoice
    data?.overDueAmountsAndCounts?.invoices?.totalCount && {
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.overDueAmountsAndCounts?.invoices?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.overDueAmountsAndCounts?.invoices?.totalCount),
      title: `Overdue Invoice${
        data?.overDueAmountsAndCounts?.invoices?.totalCount > 1 ? 's' : ''
      }`,
      link: '/receivables/invoices?status=overdue',
    },

    // Pending invoice
    data?.inReviewAmountsAndCounts?.invoices?.totalCount && {
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.inReviewAmountsAndCounts?.invoices?.[defaultCurrency]?.amount / 100 ||
              0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.inReviewAmountsAndCounts?.invoices?.totalCount),
      title: `Invoice${
        data?.inReviewAmountsAndCounts?.invoices?.totalCount > 1 ? 's' : ''
      } to review`,
      link: '/receivables/invoices?status=verifying',
    },

    //card request
    data?.pendingAmountsAndCounts?.cards?.totalCount && {
      description: 'Physical or virtual cards requests awaiting to be reviewed',
      icon: PendingCards,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.cards?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.cards?.totalCount),
      title: ` card Request${
        data?.pendingAmountsAndCounts?.cards?.totalCount > 1 ? 's' : ''
      }`,
      link: '/cards/all-cards?status=pending',
    },

    // Pending Bills
    data?.pendingAmountsAndCounts?.bills?.totalCount && {
      description:
        'Your bills due, you can pay now or mark as paid if they are already paid',
      icon: Invoices,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.bills?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.bills?.totalCount),
      title: 'Pending bills',
      link: '/expenses/bills?status=pending',
    },

    // Scheduled Trx
    data?.pendingAmountsAndCounts?.payments?.totalCount && {
      description: 'Your schedule transaction that are close to their payments date',
      icon: PendingRequest,
      total: (
        <CurrencyFormat
          prefix={getCurrency(defaultCurrency)}
          value={numFormatter(
            data?.pendingAmountsAndCounts?.payments?.[defaultCurrency]?.amount / 100 || 0,
          )}
          displayType="text"
          thousandSeparator={true}
          isNumericString
        />
      ),
      count: aliaseCount(data?.pendingAmountsAndCounts?.payments?.totalCount),
      title: `Payment${
        data.pendingAmountsAndCounts?.payments?.totalCount > 1 ? 's' : ''
      } due`,
      link: '/transactions/scheduled?status=active&next_transfer=' + new Date(),
    }, // should be tomorrow tho + 1
  ].filter((item) => !!item);

  let alerts = [
    data?.alerts?.uncategorizedTransactions && {
      description:
        'Uncategorized transactions, you can categorize them to have better insights on your finances',
      icon: PendingCards,
      title:
        data.alerts?.uncategorizedTransactions +
        ` Uncategorized transaction${
          data.alerts?.uncategorizedTransactions > 1 ? 's' : ''
        }`,
      link: '/transactions/payments?status=uncategorized',
    },
    data?.alerts?.policiesViolated && {
      description:
        'Payments or operations that violate the rules and policies put in place, click to review them and take actions',
      icon: PendingRequest,
      title:
        data.alerts?.policiesViolated +
        ` Polic${data.alerts?.policiesViolated > 1 ? 'ies' : 'y'} violated`,
      link: '/requests/reimbursements?status=pending',
    },
    data?.alerts?.activeBudgets && {
      description: 'Your current active budgets',
      icon: ApprovedTransaction,
      title:
        data.alerts?.activeBudgets +
        ` Active budget${data.alerts?.activeBudgets > 1 ? 's' : ''}`,
      link: '/expenses?status=active',
    },
    data?.alerts?.lowBudgets && {
      description:
        'Budgets low on funds can make your teams or payments fail disrupting your work and your teams work',
      icon: ApprovedTransaction,
      title: `Budget${data?.alerts?.lowBudgets > 1 ? 's are' : ' is'} low on fund`,
      link: '/expenses?status=low',
    },
  ].filter((item) => !!item);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_BLOCK_DATE_RANGE, blockType: 'selectedDateRange' });
    };
  }, []);

  useEffect(() => {
    if (
      company.code &&
      dateRange?.from !== null &&
      company.onboardingStatus === 'approved'
    ) {
      const { from, to } = dateRange;
      dispatch(getPayins({ code: company.code, params: { from, to, direction: 'in' } }));
    }
  }, [company.code, dateRange]);

  // Payout date range here
  useEffect(() => {
    if (
      company.code &&
      dateRange?.from !== null &&
      company.onboardingStatus === 'approved'
    ) {
      const { from, to } = dateRangeMoneyOut;

      dispatch(
        getPayouts({ code: company.code, params: { from, to, direction: 'out' } }),
      );
    }
  }, [company.code, dateRangeMoneyOut]);

  const createDatePayload = (date) => {
    let { from, to } = getFromAndTo(date);
    return {
      params: { from, to },
    };
  };

  useEffect(() => {
    if (monoAuthCode) dispatch(sendMonoAuthCode({ code: company.code, monoAuthCode }));
  }, [monoAuthCode]);

  useEffect(() => {
    if (data) {
      const { disbursed, available, topSpenders, chart, totalSpent } = data;
      setOverviewTotal({
        totalDisbursed: {
          amount: disbursed[defaultCurrency]?.amount,
          percentage: getPercentage(1000, disbursed[defaultCurrency]?.amount),
        },
        totalBalance: {
          amount: (available && available[defaultCurrency]) || [],
          percentage: getPercentage(1000, available[defaultCurrency]),
        },
        topSpenders: topSpenders || [],
      });
      setChartData({
        chart: buildChartDataFromTransaction(chart[defaultCurrency] || []),
        totalSpent: (totalSpent && totalSpent[defaultCurrency]) || [],
        totalPercentage: 0,
      });
    }
  }, [data, defaultCurrency]);

  const initiatePayment = (payload) => {
    dispatch(getPaymentLink({ code: company.code, payload }));
  };

  const [paymentVerified, setPaymentVerified] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const verifyPayment = (reference) => {
    dispatch(verifyPaymentReference({ code: company.code, reference }));
  };

  const getReauthorizationCode = (authorizationCode) => {
    dispatch(getAccountReauthorizationCode({ code: company.code, authorizationCode }));
  };

  useEffect(() => {
    if (reauthorizationCodeData && reauthorizationCodeData?.token) {
      setReauthorizationCode(reauthorizationCodeData?.token);
    }
  }, [reauthorizationCodeData]);

  useEffect(() => {
    if (dataPaymentLink && dataPaymentLink?.payment_link) {
      window.location = dataPaymentLink?.payment_link;
    }
  }, [dataPaymentLink]);

  useEffect(() => {
    if (verifyPaymentReferenceData) {
      if (verifyPaymentReferenceData?.status === 'success') {
        setPaymentFailed(false);
        setPaymentVerified(true);
      } else {
        setPaymentVerified(false);
        setPaymentFailed(true);
      }
    }
  }, [verifyPaymentReferenceData]);

  useEffect(() => {
    if (searchParams.get('funding') === 'true') {
      setIsModalOpen(true);
      setFundStep(1);
    }
    if (searchParams.get('isGenerateStatement')) {
      setStatementModal(true);
    }
  }, [searchParams]);

  const loadingState =
    loading ||
    loadingPaymentLink ||
    verifyingPayment ||
    fetchingReauthenticationCode ||
    isLoading;

  if (loadingState) {
    return <OverviewLoader />;
  }

  const goTo = (path) => {
    history.push(path);
  };

  const closeStatementModal = () => {
    setStatementModal(false);
  };

  const openStatementModal = () => {
    setStatementModal(true);
  };

  const COLOR_MAP = [
    { bg: '#F5F5F4', color: '#79716B' },
    { bg: '#D1E9FF', color: '#194185' },
    { bg: '#FEF7C3', color: '#713B12' },
    { bg: '#CCFBEF', color: '#134E48' },
  ];

  const getSpendersIcons = (spenders) => {
    if (!(Array.isArray(spenders) && spenders.length)) return null;
    return [
      ...spenders.slice(0, 2).map((spender, index) => (
        <Tooltip
          placement="top"
          title={`${spender.firstName} ${spender.lastName}`}
          key={spender.firstName}
          color={'#000'}
        >
          <div className="icon-wrappers" key={index}>
            <span className={`arrow-wrapper ${index === 0 ? ' active' : ''}`}>
              {spender.firstName.charAt(0)}
              {spender.lastName.charAt(0)}
            </span>
          </div>
        </Tooltip>
      )),
      spenders.length > 2 && (
        <Tooltip
          placement="top"
          title={spenders
            .slice(2)
            .map((spender) => `${spender.firstName} ${spender.lastName}`)
            .join(', ')}
          key={'the-rest90'}
          color={'#000'}
        >
          <div className="icon-wrappers counter">
            <span className="arrow-wrapper">+{spenders.length - 2}</span>
          </div>
        </Tooltip>
      ),
    ];
  };

  return (
    <div className="page-wrapper pb-5">
      <div className="w-100">
        <SubHeader
          firstName={firstName}
          openModal={openStatementModal}
          openSwapModal={handleSwapping}
        />

        <FundsModal
          closeHandler={closeHandler}
          isModalOpen={isModalOpen}
          setInsideModalClick={setInsideModalClick}
          fundType={toggleBalance}
          fundStep={fundStep}
          setFundStep={setFundStep}
          setMonoAuthCode={setMonoAuthCode}
          handleBack={() => setFundStep(1)}
          accounts={company.accounts}
          initiatePayment={initiatePayment}
          verifyPayment={verifyPayment}
          paymentVerified={paymentVerified}
          paymentFailed={paymentFailed}
          getReauthorizationCode={getReauthorizationCode}
          reauthorizationCode={reauthorizationCode}
        />
        <StatementModal isModalOpen={statementModal} closeHandler={closeStatementModal} />

        <SwapModal
          companyCode={company.code}
          accounts={accounts}
          isOpen={openSwapModal}
          closeHandler={handleSwapping}
        />

        <section className="overview-holder">
          <div className="overview-top-data position-relative onDesktop">
            <Balances
              balances={balances}
              handleSwap={handleSwapping}
              defaultCurrency={defaultCurrency}
              openModal={() => setIsModalOpen(true)}
            />
          </div>

          {/* Mobile here */}
          <div className="overview-data position-relative onMobile">
            <TotalAmount
              type={`${balanceName} total balance`}
              title={
                <>
                  <CurrencyFormat
                    prefix={getCurrency(defaultCurrency)}
                    value={numFormatter(overviewTotal.totalBalance.amount / 100 || 0)}
                    displayType="text"
                    thousandSeparator={true}
                    isNumericString
                  />
                  {/* <div className="link-icon">
                    <NairaSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 0 })}
                      onClick={handleToggleBalance}
                    />

                    <DollarSign
                      className={cs('icon-btn', { ['not-active']: toggleBalance === 1 })}
                      onClick={handleToggleBalance}
                    />
                  </div> */}
                </>
              }
              handleClick={isAdmin ? seeBreakDown : null}
              widthLink={isAdmin && 'See breakdown'}
            />
          </div>

          <Row>
            <div className="budget-analytics mt-4 pt-1">
              <div className="grid-col-2 gap-4">
                <OverviewCard
                  customClass="barred"
                  title={
                    <div className="d-flex align-items-center gap-2">
                      <span className="text">Upcoming actions</span>
                    </div>
                  }
                  isEmpty={!pendingTasks?.length}
                >
                  {pendingTasks.slice(0, 5).map((item, index) => {
                    return (
                      <div
                        className={classNames('card-list__item cursor', {})}
                        key={index}
                        onClick={() => {
                          return goTo(item.link);
                        }}
                      >
                        <div className="card-list__content d-flex align-items-center ">
                          <div className="pill">
                            <span
                              className={`count-value ${
                                item?.count?.toString().length > 3 ? 'smaller-text' : ''
                              }`}
                            >
                              {numFormatter(item?.count || 0)}
                            </span>
                          </div>
                          <span className="card-list__text">{item.title}</span>
                        </div>

                        <div className="total-region">
                          {item.total}
                          <ChevronRight stroke="#79716B" />
                        </div>
                      </div>
                    );
                  })}
                </OverviewCard>

                <OverviewCard
                  customClass="barred"
                  title={
                    <div className="d-flex align-items-center gap-2 justify-content-between w-100">
                      <div className="d-flex gap-2 align-items-center">
                        <span className="text">Recent transactions</span>
                      </div>

                      {transactions?.length && (
                        <div
                          className={`cursor add-custom add-button ms-auto h-32`}
                          onClick={() => history.push('/transactions/payments')}
                        >
                          All transactions
                        </div>
                      )}
                    </div>
                  }
                  isEmpty={!transactions?.length}
                >
                  {transactions?.slice(0, 5).map((transaction, i) => {
                    return (
                      <div key={i} className={classNames('card-list__item', {})}>
                        <div className="card-list__content d-flex align-items-center ">
                          <ImgCard
                            size="medium"
                            customGap="c-gap-10"
                            fullWidth={false}
                            initials={
                              <>
                                {transaction?.accountName
                                  ? transaction?.accountName?.charAt(0).toUpperCase()
                                  : '-'}
                              </>
                            }
                          />

                          <span className="card-list__text">
                            {transaction?.accountName || '-'}
                          </span>
                        </div>
                        <div className="total-region" style={{ gap: 0 }}>
                          <CurrencyFormat
                            prefix={getCurrency(defaultCurrency)}
                            value={numFormatter(transaction?.amount / 100)}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                          />
                        </div>
                      </div>
                    );
                  })}
                </OverviewCard>
              </div>

              <div className="grid-col-2 mt-4 pt-1 gap-4">
                <OverviewCard
                  customClass="px-0"
                  containerClass="cashflow"
                  title={
                    <div className="d-flex w-100 py-1">
                      <div className="overview-display__cards plain">
                        <p className="caption">Money in</p>

                        <p className="value" style={{ color: '#05603A' }}>
                          <CurrencyFormat
                            prefix={getCurrency(defaultCurrency)}
                            value={numFormatter(
                              data?.totalReceived?.[defaultCurrency] / 100 || 0,
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            renderText={(formattedValue) => {
                              const [integerPart, decimalPart] =
                                formattedValue.split('.');
                              return (
                                <span>
                                  {integerPart}
                                  {decimalPart && (
                                    <span style={{ color: '#BFBFBD' }}>
                                      .{decimalPart}
                                    </span>
                                  )}
                                </span>
                              );
                            }}
                          />
                        </p>
                      </div>

                      <div className="ms-auto">
                        <DateRangeFilter
                          customClass="h-32"
                          selectedRange={selectedRange}
                        />
                      </div>
                    </div>
                  }
                  isEmpty={!payin?.length && !payinLoading}
                  placeHolderText={'No pay in within the period'}
                >
                  {payinLoading ? (
                    <Skeleton.Node
                      className="px-4 w-100"
                      active
                      style={{ borderRadius: 12, width: '100%', height: '350px' }}
                    >
                      <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                    </Skeleton.Node>
                  ) : (
                    <AreaChartView
                      hasCustomHeight={true}
                      showTotalSpent={false}
                      currency={defaultCurrency}
                      totalPercentage={100}
                      identifier="payins"
                      customStyle={{ height: 0 }}
                      data={buildChartDataFromTransaction(payin)}
                    />
                  )}

                  {data?.topSources?.[defaultCurrency]?.length && (
                    <div className="mx-4 pt-3 cashflow-break">
                      <p className="header">Top sources</p>

                      {data.topSources[defaultCurrency].slice(0, 4).map((source, i) => {
                        return (
                          <div key={i} className={classNames('card-list__item', {})}>
                            <div className="card-list__content d-flex align-items-center ">
                              <ImgCard
                                size="medium"
                                customGap="c-gap-10"
                                fullWidth={false}
                                initials={
                                  <>
                                    {source?.accountName
                                      ? source?.accountName?.charAt(0).toUpperCase()
                                      : '-'}
                                  </>
                                }
                              />

                              <span className="card-list__text">
                                {source?.accountName || '-'}
                              </span>
                            </div>

                            <div className="total-region">
                              <CurrencyFormat
                                prefix={getCurrency(defaultCurrency)}
                                value={numFormatter(source?.amount / 100)}
                                displayType="text"
                                thousandSeparator={true}
                                isNumericString
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </OverviewCard>

                <OverviewCard
                  customClass="px-0 "
                  containerClass="cashflow"
                  title={
                    <div className="d-flex w-100 py-1">
                      <div className="overview-display__cards plain">
                        <p className="caption">Money out</p>

                        <p className="value">
                          -
                          <CurrencyFormat
                            prefix={getCurrency(defaultCurrency)}
                            value={numFormatter(
                              data?.totalSpent?.[defaultCurrency] / 100 || 0,
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            isNumericString
                            renderText={(formattedValue) => {
                              const [integerPart, decimalPart] =
                                formattedValue.split('.');
                              return (
                                <span>
                                  {integerPart}
                                  {decimalPart && (
                                    <span style={{ color: '#BFBFBD' }}>
                                      .{decimalPart}
                                    </span>
                                  )}
                                </span>
                              );
                            }}
                          />
                        </p>
                      </div>

                      <div className="ms-auto">
                        <DateRangeFilter
                          customClass="h-32"
                          trigger="payout"
                          selectedRange={selectedRangeMoneyOut}
                        />
                      </div>
                    </div>
                  }
                  isEmpty={!payout && !payoutLoading}
                  placeHolderText={'No pay out within the period'}
                >
                  {payoutLoading ? (
                    <Skeleton.Node
                      className="px-4 w-100"
                      active
                      style={{ borderRadius: 12, width: '100%', height: '350px' }}
                    >
                      <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                    </Skeleton.Node>
                  ) : (
                    <AreaChartView
                      hasCustomHeight={true}
                      showTotalSpent={false}
                      currency={defaultCurrency}
                      totalPercentage={100}
                      identifier="payouts"
                      data={buildChartDataFromTransaction(payout)}
                    />
                  )}
                  {data?.topSpenders?.[defaultCurrency]?.length && (
                    <div className="mx-4 pt-3 cashflow-break">
                      <div className="d-flex align-items-center mb-2">
                        <p className="header" style={{ marginBottom: 0 }}>
                          Top spend
                        </p>

                        {payout?.length && (
                          <button
                            className="view-all ms-auto"
                            onClick={() => history.push(`/expenses/vendors`)}
                          >
                            View all
                            <ArrowRight color="#D28B28" width="12" height="12" />
                          </button>
                        )}
                      </div>

                      {data.topSpenders[defaultCurrency].slice(0, 3).map((spender, i) => {
                        return (
                          <div key={i} className={classNames('card-list__item', {})}>
                            <div className="card-list__content d-flex align-items-center ">
                              <ImgCard
                                size="medium"
                                customGap="c-gap-10"
                                fullWidth={false}
                                initials={
                                  <>
                                    {spender?.firstName
                                      ? spender?.firstName?.charAt(0).toUpperCase()
                                      : '-'}
                                  </>
                                }
                              />

                              <span className="card-list__text">
                                {`${spender?.firstName} ${spender?.lastName}`}
                              </span>
                            </div>

                            <div className="total-region" style={{ gap: 0 }}>
                              <span>-</span>
                              <CurrencyFormat
                                prefix={getCurrency(defaultCurrency)}
                                value={numFormatter(spender?.amount / 100)}
                                displayType="text"
                                thousandSeparator={true}
                                isNumericString
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </OverviewCard>
              </div>
            </div>
          </Row>
        </section>
      </div>
    </div>
  );
};
export default Overview;
