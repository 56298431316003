import React from 'react';
import { ReactComponent as AppLogo } from 'assets/logos/appLogo.svg';
import { Link } from 'react-router-dom';
import SetupStage from './SetupStage';
import { MailIcon } from 'assets/icons';
import './styles.scss';
import SliderComponent from './SliderComponent';
import { useHistory } from 'react-router-dom';

const Layout = ({
  children,
  hideSteps = false,
  isLogin,
  menus = [],
  slider = [],
  hideSlider = false,
}) => {
  const { push } = useHistory();
  const year = new Date();
  return (
    <div className="onboarding__layout-wrapper">
      <div
        className="onboarding__layout-main overflow-hidden"
        style={{ width: hideSlider ? '100%' : '' }}
      >
        <div className="d-flex align-items-center justify-content-between header w-100">
          <div>
            <AppLogo className="app-logo" />
          </div>

          <div>
            {!hideSteps && <SetupStage menus={menus} />}
            {isLogin && (
              <div className="d-flex align-items-center gap-2">
                <span className="text-xs text-gray">Don&apos;t have an account?</span>
                <botton
                  className="outline-button cursor"
                  onClick={() => push('/register')}
                >
                  Sign up
                </botton>
              </div>
            )}
          </div>
        </div>
        <div className="w-100 ms-auto h-100">
          <div className="onboarding__layout-children hide-scrollbar">{children}</div>
        </div>
        <div className="w-100 position-absolute bottom-0 footer d-flex align-items-center justify-content-between">
          <div className="text-sm mail-tag">© {year.getFullYear()} Bujeti ltd.</div>
          <a
            href="mailto:support@bujeti.com"
            className="text-sm d-flex align-items-center gap-2 mail-tag"
          >
            <MailIcon stroke="#79716B" width="16" height="16" /> support@bujeti.com
          </a>
        </div>
      </div>
      {!hideSlider && (
        <div className="onboarding__layout-slider">
          <SliderComponent contents={slider} />
        </div>
      )}
    </div>
  );
};

export default Layout;

export const OverflowWrapLayout = ({ children, height, maxWidth = '28rem' }) => {
  return (
    <div className="hide-scrollbar h-100" style={{ overflowY: 'scroll' }}>
      <div style={{ margin: '20px auto 20px', maxWidth, height }}>{children}</div>
    </div>
  );
};
