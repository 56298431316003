import {
  BankIcon,
  CreditCardPlus,
  KYBIcon,
  SafeIcon,
  ShieldAlertIcon,
  UserPlus,
} from 'assets/icons';
import ModalContainer from 'components/ModalContainer';
import ServerFileUpload from 'components/UI/FileUpload/ServerFileUpload';
import ImgCard from 'components/UI/ImgCard';
import { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, uploadOnboardingDocuments } from 'redux/actions/CompaniesAction';
import { allPermissions } from 'utils/AllowedTo';
import QuickActionBanner from './components/QuickActionBanner';
import TodoItems from './components/TodoItems';
import EmployeeOnboarding from './employeeOnboarding';
import './styles.scss';
import { getInitials } from 'utils/helper';
import { useHistory } from 'react-router-dom';

const Onboarding = () => {
  const [menus, setMenus] = useState([]);
  const [documents, setDocuments] = useState({ incorporationCertificate: '' });
  const [uploadingFile, setUploadingFile] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const {
    user: { data: { user } = {} },
  } = useSelector(({ auth }) => auth);

  const { isAdmin, onboardingStatus } = allPermissions();

  const {
    getCompany: { loading: loadingCompany, data },
    uploadOnboardingDocuments: { loading: loadingUpload, success: successUpload },
  } = useSelector(({ companies }) => companies);

  //
  useMemo(() => {
    setMenus([
      {
        bntName: 'Complete KYC',
        icon: <ShieldAlertIcon width="20" height="20" stroke="#79716B" />,
        title: 'Complete business KYC',
        withAction: false,
        isActive: true,
        isComplete: false,
        link: '/get-started/business-kyc',
      },
      {
        bntName: 'Setup profile',
        icon: <KYBIcon width="20" height="20" stroke="#79716B" />,
        title: 'Complete admin KYB',
        withAction: false,
        isActive: true,
        isComplete: false,
        link: '/get-started/admin-kyb',
      },
      {
        bntName: 'Invite member',
        icon: <UserPlus color="#79716B" />,
        title: 'Invite team members',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/teams/people',
        modal: true,
      },
      {
        bntName: 'Add funds',
        icon: <SafeIcon />,
        title: 'Deposit funds into your Bujeti account',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/accounts',
        modal: true,
      },
      {
        bntName: 'Connect bank',
        icon: <BankIcon width="20" height="20" stroke="#79716B" />,
        title: 'Create or connect a bank account',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/accounts',
        modal: true,
      },
      {
        bntName: 'Create card',
        icon: <CreditCardPlus stroke="#79716B" />,
        title: 'Create a virtual card',
        // desc: '',
        withAction: false,
        isActive: false,
        isComplete: false,
        link: '/cards',
        modal: true,
      },
    ]);
  }, []);

  useEffect(() => {
    if (successUpload) {
      setOpen(false);
      dispatch(
        getCompany({ code: data?.code, includeDocuments: true, includeSteps: true }),
      );
    }
  }, [successUpload]);

  const foundDocs = data?.documents.find((document) =>
    ['incorp_C', 'cac'].includes(document.value),
  );

  const handleSubmit = () => {
    dispatch(
      uploadOnboardingDocuments({
        code: data.code,
        [foundDocs?.value]: documents[foundDocs?.value],
      }),
    );
  };

  const isApproved = onboardingStatus === 'approved';
  const isUploaded = !['pending', 'rejected'].includes(foundDocs?.status);
  const title = isUploaded
    ? 'Discover more possibilities'
    : 'Get started now and unlock basic features';
  const description = isUploaded
    ? `As a verified business, you'll get full access to the Bujeti platform.`
    : 'Upload your CAC Certificate of Incorporation for basic access. Unlock full features later with additional documents.';

  const actionName = isUploaded ? 'Start now' : 'Upload certificate';

  const actionClick = () => {
    isUploaded ? push('/get-started/business-kyc') : setOpen(true);
  };

  return (
    <section className="onboarding-section">
      <div className="welcome-header">
        <h1>
          Welcome to Bujeti, <span>{user?.firstName || 'User'}</span>
        </h1>
        <ImgCard initials={getInitials(user?.firstName || 'User')} fullWidth={false} />
      </div>
      <div
        className="max-w mx-auto overview-holder"
        style={{ height: `calc(100vh - 100px)` }}
      >
        {!isApproved && isAdmin && (
          <QuickActionBanner
            description={description}
            title={title}
            actionName={actionName}
            hideLearn={!isUploaded}
            onClick={actionClick}
          />
        )}

        {isAdmin ? <TodoItems menus={menus} /> : <EmployeeOnboarding />}

        <Modal show={open} centered dialogClassName="custom-dialog">
          <ModalContainer
            onCancel={() => setOpen(false)}
            title="Upload your certificate"
            subTitle={foundDocs?.name}
            onConfirm={handleSubmit}
            loading={loadingUpload}
            disabled={uploadingFile}
          >
            <Row className="mb-2">
              <ServerFileUpload
                label={`${foundDocs?.name} *`}
                supportType="JPEG, PNG, and PDF formats, up to 5 MB."
                uploadedFile={documents.incorporationCertificate}
                onChange={({ assetCode } = {}) =>
                  setDocuments({ ...documents, [foundDocs?.value]: assetCode })
                }
                setUploadingFile={setUploadingFile}
                uploadingFile={uploadingFile}
                name={`cac-doc`}
              />
            </Row>
          </ModalContainer>
        </Modal>
      </div>
    </section>
  );
};

export default Onboarding;
