import { cloneElement } from 'react';

import { Button } from 'react-bootstrap';
import Loading from '../Loading';
import { ArrowUpIcon } from 'assets/icons';

import cs from 'classnames';
import './styles.less';

export const ActiveButton = ({
  icon,
  text,
  position = 'top', // bottom, left
  variant = 'dark', // 'light'
  customClass = '',
  ...props
}) => {
  const buttonContent = (
    <>
      <div className="arrow-icon-wrapper">
        {cloneElement(icon, {
          className: cs({
            'first-svg': !props.disabled,
            [position]: true,
          }),
        })}

        {cloneElement(icon, {
          className: cs({
            'second-svg': !props.disabled,
            [position]: true,
          }),
        })}
      </div>
      {text && <span>{text}</span>}
    </>
  );

  return variant === 'dark' ? (
    <CustomButton className={cs('add-button', customClass)} {...props}>
      {buttonContent}
    </CustomButton>
  ) : (
    <button className={cs('add-button', customClass)} {...props}>
      {buttonContent}
    </button>
  );
};

export const SendFundsButton = ({ ...props }) => {
  return <ActiveButton text="Send funds" {...props} icon={<ArrowUpIcon />} />;
};

const CustomButton = ({
  withoutBg = false,
  className,
  customClass = false,
  fullWidth = false,
  children,
  loading,
  ...props
}) => {
  return (
    <Button
      className={cs(
        withoutBg ? 'customButtonWithoutBg' : customClass ? '' : 'customButton',
        { [className]: className },
        { ['width-full']: fullWidth },
      )}
      {...props}
    >
      {loading ? <Loading color="#D28B28" size={18} /> : children}
    </Button>
  );
};
export default CustomButton;
