import {
  BankIcon,
  BoxedShield,
  CreditCardLock,
  PresentationIcon,
  StarIcon,
} from 'assets/icons';
import CustomButton from '../UI/CustomButton';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { allPermissions } from 'utils/AllowedTo';

const list = [
  { Icon: BankIcon, label: 'Open a dedicated Bujeti business account' },
  { Icon: CreditCardLock, label: 'Issue and manage corporate cards for your team' },
  { Icon: PresentationIcon, label: 'Access advanced expense tracking and reporting' },
  { Icon: StarIcon, label: 'And much more!' },
];

const OnboardingNoticeDialog = ({ onCancel, onConfirm, extraChild }) => {
  const { push } = useHistory();
  const { isAdmin } = allPermissions();

  const handleContinue = () => {
    window.location.href = '/get-started/business-kyc';
  };
  return (
    <div>
      <div className="confirm-modal-overlay" onClick={onCancel} />
      <div className="confirm-modal-wrapper position-relative">
        <div className="confirm-modal-body p-0" style={{ width: '424px' }}>
          <div className="px-4 pt-4 mb-4 onboarding-access d-flex align-items-center justify-content-center flex-column">
            <span className="icon">
              <BoxedShield />
            </span>
            <h2 className="text-center mt-2">
              Unlock full access and advanced tools with business verification
            </h2>
            <div className="features">
              {list.map(({ Icon, label }, index) => (
                <div key={index} className="list">
                  <Icon height="20" width="20" stroke="#D28B28" /> <span>{label}</span>
                </div>
              ))}
            </div>
            {extraChild}
          </div>
          {isAdmin && (
            <div className="custom-modal_footer  d-flex w-100 gap-3">
              <CustomButton fullWidth className="confirm-btn" onClick={handleContinue}>
                Complete now
              </CustomButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OnboardingNoticeDialog;
