import cs from 'classnames';
import './styles.scss';
import { truncateText } from 'utils/helper';

const DoubleStringType = ({ value, fontBold, withImg, truncate = true }) => {
  if (!value) return <div />;

  return (
    <span
      className={cs(
        'string-type-font',
        { ['fw-light-bold']: fontBold },
        { ['d-flex  gap-3']: withImg },
      )}
      style={value?.textColor ? { color: value.textColor } : undefined}
    >
      {withImg && (
        <span className="pt-1" style={{ width: 40 }}>
          {value.img}{' '}
        </span>
      )}

      <div>
        {truncate ? truncateText(value.value, 0, 25) : value.value}
        <p className="with-substring">
          {' '}
          {truncate ? truncateText(value.subValue, 0, 25) : value.subValue}
        </p>
      </div>
    </span>
  );
};
export default DoubleStringType;
