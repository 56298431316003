import { Skeleton } from 'antd';
import { ArrowLeft, BuildingIcon } from 'assets/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BusinessKycTabs from '../components/BusinessKycTabs';
import BusinessKycTabsView from '../components/BusinessKycTabView';
import CompleteProfile from '../employeeOnboarding/CompleteProfile';
import UpdateBankAccount from '../employeeOnboarding/UpdateBankAccount';
import '../styles.scss';
import { allPermissions, returnPermissions } from 'utils/AllowedTo';

const employeeTabs = [
  { name: 'Profile details', icon: <BuildingIcon />, key: 'profileDetails' },
  { name: 'Bank details', icon: <BuildingIcon />, key: 'bankDetails' },
];

const EmployeeOnboarding = () => {
  const { push } = useHistory();
  const [mounted, setMounted] = useState(false);

  const [key, setKey] = useState('profileDetails');

  const {
    user: { success: getUserSuccess },
  } = useSelector(({ auth }) => auth);

  const {
    getCompany: { loading: loadingCompany, data: companyData },
  } = useSelector(({ companies }) => companies);
  const activeIndex = employeeTabs?.findIndex((tab) => tab?.key === key);

  const {
    addBeneficiaryBank: { success },
    getBeneficiaryBank: { data = [], success: successGetBank },
  } = useSelector(({ beneficiaries }) => beneficiaries);

  const { isAdmin, userStatus, permissions } = allPermissions();

  const canViewDash = returnPermissions({
    permissions,
    scopes: ['org-*', 'dash-view', 'dash-*'],
  });

  const getUserBank = data?.find((item) => item.ownerType === 'user');

  const handleSelect = (tabKey) => {
    setKey(tabKey);
  };

  useEffect(() => {
    if (companyData?.code && !loadingCompany) {
      setMounted(true);
    }
  }, [companyData?.code]);

  useEffect(() => {
    if (getUserSuccess) {
      if (!isAdmin && userStatus !== 'active') setKey('profileDetails');
      else push(canViewDash ? '/' : '/expenses/budgets');
    }
    if (successGetBank) {
      if (!isAdmin && !getUserBank) setKey('bankDetails');
      else push(canViewDash ? '/' : '/expenses/budgets');
    }
  }, [getUserSuccess, successGetBank]);

  const components = useMemo(
    () => [
      <CompleteProfile key="profileDetails" />,
      <UpdateBankAccount key="bankDetails" />,
    ],
    [],
  );

  const title = 'Complete Profile Details';
  const description =
    'Get ready to use Bujeti by verifying a few organisational details about your company.';

  const formUI = (
    <>
      <div className="todo-wrapper py-0 pt-3 border-bottom bg-transparent">
        <div className="setup-title m-0 p-0">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
      <section className="onboarding-section m-0 p-0">
        <div className="business-kyc-section m-0 p-0">
          {!mounted && loadingCompany ? (
            <LoadingSkeleton />
          ) : (
            <div className="forms-tab-wrapper">
              <div>
                <BusinessKycTabs
                  tabs={employeeTabs}
                  active={key}
                  onSelect={handleSelect}
                />
              </div>

              <div className="business-tab-view">
                <BusinessKycTabsView index={activeIndex} tabs={components} />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
  return (
    <>
      {!isAdmin ? (
        formUI
      ) : (
        <section className="onboarding-section">
          <div
            className="business-kyc-section max-w mx-auto overview-holder"
            style={{ height: `calc(100vh - 100px)` }}
          >
            <div className="back-btn" onClick={() => push('/get-started')}>
              <ArrowLeft color="#D28B28" /> <div>Back</div>
            </div>
            {formUI}
          </div>
        </section>
      )}
    </>
  );
};

export default EmployeeOnboarding;

const LoadingSkeleton = () => {
  return (
    <div className="forms-tab-wrapper">
      <div>
        <div className="mb-3 mt-3 grid grid-col-2 gap-2 align-items-center">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>

        <div className="mb-3">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>
        <div className="mb-3">
          <Skeleton.Input
            active
            className="rounded-2"
            style={{ width: ' 242px' }}
            size={42}
          />
        </div>

        <div className="mb-4">
          <Skeleton active style={{ width: ' 242px' }} paragraph={{ rows: 1 }} />
        </div>
      </div>

      <div className="business-tab-view">
        <div className="mb-3 mt-3 pb-3 border-bottom">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>

        <div className="mb-3 border-bottom pb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>
        <div className="mb-3 pb-3">
          <Skeleton.Input active className="rounded-2 w-100" size={42} />
        </div>
      </div>
    </div>
  );
};
