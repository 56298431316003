import { uuid4 } from '@sentry/utils';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Skeleton } from 'antd';
import NoData from 'components/NoData';
import Table from 'components/Table';
import Loading from 'components/UI/Loading';
import { Modal } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBillingHistoryAction,
  getCompanySubscription,
} from 'redux/actions/BillingActions';
import {
  buildInvoiceHistoryTable,
  capitalizeFirstLetter,
  convertNaNToZero,
  getCurrency,
  getFormattedDate,
} from 'utils/helper';
import { InvoiceHistoryColumn } from 'utils/mockData';

import CancelSubscriptionModal from './CancelSubscriptionModal';
import ChangeBillingMethod from './ChangeBillingMethod';
import './billings.styles.scss';
import DownloadPdfComponent from './DownloadPdfComponent';

const Billings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState([]);
  const [list, setList] = useState();
  const [openBillingMethod, setOpenBillingMethod] = useState(false);
  const pdfDownloadRef = useRef(null);

  const {
    getCompany: { data: companyData },
  } = useSelector(({ companies }) => companies);

  const {
    fetchBillingHistory: { loading: historyLoading, data: histories },
    getCompanySubscription: { data },
  } = useSelector(({ billing }) => billing);

  const { user } = useSelector(({ auth }) => auth);

  const onHandleLocationChange = (route) => {
    history.push(route);
  };

  const handleOpenMethod = () => {
    setOpenBillingMethod(!openBillingMethod);
  };

  useEffect(() => {
    dispatch(fetchBillingHistoryAction());
    dispatch(getCompanySubscription());
  }, []);

  const downloadPDF = () => {
    // event?.stopPropagation();
    // event?.preventDefault();
    pdfDownloadRef?.current?.click();
  };

  const onDownloadClick = (list) => {
    setList(list);
    downloadPDF();
  };

  return (
    <section className="billing-wrapper fade-in mt-4 pt-2">
      <section className="d-flex align-items-center gap-4">
        {historyLoading ? (
          <>
            <Skeleton.Input
              active
              className="mb-2 flex-1"
              size={248}
              style={{ borderRadius: 8, minWidth: '100%' }}
            />

            <Skeleton.Input
              active
              className="mb-2 flex-1"
              size={248}
              style={{ borderRadius: 8, minWidth: '100%' }}
            />
          </>
        ) : (
          <>
            <section className="flex-1">
              <PlanCard onHandleLocationChange={onHandleLocationChange} planInfo={data} />
            </section>

            <section className="flex-1">
              <PaymentMethod handleOpenMethod={handleOpenMethod} data={data} />
            </section>
          </>
        )}
      </section>

      <div className="billing-email-address">
        <h1>Billing email address</h1>
        <p>Invoices will be sent to this email address.</p>
        <div className="email-address">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.66699 3.83325L8.47109 8.59612C9.02207 8.9818 9.29756 9.17465 9.59721 9.24934C9.8619 9.31532 10.1387 9.31532 10.4034 9.24934C10.7031 9.17465 10.9786 8.9818 11.5296 8.59612L18.3337 3.83325M5.66699 14.6666H14.3337C15.7338 14.6666 16.4339 14.6666 16.9686 14.3941C17.439 14.1544 17.8215 13.772 18.0612 13.3016C18.3337 12.7668 18.3337 12.0667 18.3337 10.6666V5.33325C18.3337 3.93312 18.3337 3.23306 18.0612 2.69828C17.8215 2.22787 17.439 1.84542 16.9686 1.60574C16.4339 1.33325 15.7338 1.33325 14.3337 1.33325H5.66699C4.26686 1.33325 3.5668 1.33325 3.03202 1.60574C2.56161 1.84542 2.17916 2.22787 1.93948 2.69828C1.66699 3.23306 1.66699 3.93312 1.66699 5.33325V10.6666C1.66699 12.0667 1.66699 12.7668 1.93948 13.3016C2.17916 13.772 2.56161 14.1544 3.03202 14.3941C3.5668 14.6666 4.26686 14.6666 5.66699 14.6666Z"
              stroke="#6D6F6B"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {data?.contactEmail ?? 'N/A'}
        </div>
        {email.map((item) => {
          return (
            <div className="mt-3 new-email-container" key={item}>
              <input type="email" placeholder="new@email.com" className="email-address" />
              <div className="email-addressBtn">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 6.66667H7.16667C6.69996 6.66667 6.4666 6.66667 6.28834 6.57584C6.13154 6.49594 6.00406 6.36846 5.92416 6.21166C5.83333 6.0334 5.83333 5.80004 5.83333 5.33333V2.5M14.1667 17.5V12.1667C14.1667 11.7 14.1667 11.4666 14.0758 11.2883C13.9959 11.1315 13.8685 11.0041 13.7117 10.9242C13.5334 10.8333 13.3 10.8333 12.8333 10.8333H7.16667C6.69996 10.8333 6.4666 10.8333 6.28834 10.9242C6.13154 11.0041 6.00406 11.1315 5.92416 11.2883C5.83333 11.4666 5.83333 11.7 5.83333 12.1667V17.5M17.5 7.77124V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H12.2288C12.6364 2.5 12.8402 2.5 13.0321 2.54605C13.2021 2.58688 13.3647 2.65422 13.5138 2.7456C13.682 2.84867 13.8261 2.9928 14.1144 3.28105L16.719 5.88562C17.0072 6.17387 17.1513 6.318 17.2544 6.48619C17.3458 6.63531 17.4131 6.79789 17.4539 6.96795C17.5 7.15976 17.5 7.36358 17.5 7.77124Z"
                    stroke="#E5E8E3"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Save
              </div>
            </div>
          );
        })}

        {/* <span onClick={onHandleAddNew}>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00033 1.16675V12.8334M1.16699 7.00008H12.8337"
              stroke="#586068"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Add Another
        </span> */}
      </div>
      <section className="invoice-history-table">
        <div className="header">
          <h1>Billing History</h1>
          {!histories?.billingHistory?.length ? (
            <div>
              <NoData
                headerText="No Billing History"
                bodyText="All your billing transaction history would be listed here"
                withButton={false}
              />
            </div>
          ) : (
            <Table
              columns={InvoiceHistoryColumn}
              data={buildInvoiceHistoryTable(histories?.billingHistory)}
              pagination={false}
              onDownloadClick={onDownloadClick}
              hasAvatar={false}
              loading={historyLoading}
            />
          )}
        </div>
      </section>
      {list && (
        <DownloadPdfComponent
          invoiceSelected={list}
          companyData={companyData}
          pdfDownloadRef={pdfDownloadRef}
        />
      )}

      <Modal show={openBillingMethod} centered dialogClassName="custom-dialog">
        <ChangeBillingMethod onClose={handleOpenMethod} code={data?.code} />
      </Modal>
    </section>
  );
};

export default Billings;

const PlanCard = ({ onHandleLocationChange, planInfo, subCode }) => {
  const [show, setShow] = useState(false);

  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;
  const userPlan = userData?.user?.company?.paymentPlan;

  return (
    <section className="billing-card-style">
      <main className="d-flex">
        <div className="flex-1">
          {userPlan ? (
            <h1 className="title">
              {userPlan?.name === 'start' ? 'Starter' : planInfo?.paymentPlan?.name}{' '}
              <span>
                {userPlan?.name === 'start'
                  ? 'free'
                  : capitalizeFirstLetter(planInfo?.billingPeriod)}
              </span>
            </h1>
          ) : (
            'N/A'
          )}

          <p className="body">
            {userPlan?.name === 'start'
              ? 'You are on a free plan with limited access, upgrade to enjoy premium access.'
              : planInfo?.paymentPlan?.description}
          </p>
          <span className="next-billing">
            Next billing date{' '}
            <b style={{ fontWeight: 600, color: '#414240' }}>
              {userPlan?.expiryDate ? getFormattedDate(userPlan?.expiryDate) : 'N/A'}
            </b>
          </span>
        </div>
        <div className="price">
          <h1>
            <CurrencyFormat
              prefix={getCurrency(
                userPlan?.name === 'start' || !planInfo?.paymentPlan?.currency
                  ? 'NGN'
                  : planInfo?.paymentPlan?.currency,
              )}
              value={
                userPlan?.name === 'start'
                  ? 0
                  : convertNaNToZero(planInfo?.paymentPlan?.amount / 100)
              }
              displayType="text"
              thousandSeparator={true}
            />

            {userPlan?.name === 'start' ? null : (
              <span>per {planInfo?.billingPeriod === 'monthly' ? 'month' : 'year'}</span>
            )}
          </h1>
        </div>
      </main>
      <footer>
        <div className="d-flex gap-3">
          {userPlan?.expiryDate && planInfo?.status !== 'cancelled' && (
            <button onClick={() => setShow(true)}>Cancel Subscription</button>
          )}
          <button onClick={() => onHandleLocationChange('/settings/plans')}>
            Change Plan
          </button>
        </div>
      </footer>

      <CancelSubscriptionModal
        isOpen={show}
        code={planInfo?.code}
        onCancel={() => setShow(false)}
        setIsOpen={setShow}
      />
    </section>
  );
};

const PaymentMethod = ({ handleOpenMethod, data }) => {
  const { user } = useSelector(({ auth }) => auth);
  const { data: userData = {} } = user;
  const userPlan = userData?.user?.company?.paymentPlan;

  return (
    <section className="billing-card-style padding-bottom">
      <main>
        <h1 className="title">Payment method</h1>
        <p className="body"> Manage how you pay for your plan.</p>
        <div className="active-method d-flex align-items-center justify-content-between">
          <div className="lhs d-flex align-items-center">
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 6.66667V3.75055C13.3333 3.05743 13.3333 2.71087 13.1873 2.4979C13.0598 2.31182 12.8622 2.18542 12.6398 2.14757C12.3852 2.10425 12.0706 2.24948 11.4413 2.53994L4.04918 5.95166C3.48792 6.2107 3.20729 6.34022 3.00175 6.5411C2.82005 6.71868 2.68135 6.93546 2.59625 7.17486C2.5 7.44566 2.5 7.75473 2.5 8.37289V12.5M13.75 12.0833H13.7583M2.5 9.33333L2.5 14.8333C2.5 15.7668 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1586 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1586 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7668 17.5 14.8333V9.33334C17.5 8.39992 17.5 7.9332 17.3183 7.57669C17.1586 7.26308 16.9036 7.00811 16.59 6.84833C16.2335 6.66667 15.7668 6.66667 14.8333 6.66667L5.16667 6.66667C4.23325 6.66667 3.76654 6.66667 3.41002 6.84832C3.09641 7.00811 2.84144 7.26308 2.68166 7.57668C2.5 7.9332 2.5 8.39991 2.5 9.33333ZM14.1667 12.0833C14.1667 12.3135 13.9801 12.5 13.75 12.5C13.5199 12.5 13.3333 12.3135 13.3333 12.0833C13.3333 11.8532 13.5199 11.6667 13.75 11.6667C13.9801 11.6667 14.1667 11.8532 14.1667 12.0833Z"
                  stroke="#414240"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h6>
              {['balance', 'budget'].includes(data?.method)
                ? 'My Bujeti Balance'
                : data?.method === 'directdebit'
                ? 'Bank account'
                : 'None'}

              <div className="text-xs">{data?.source?.name}</div>
            </h6>
          </div>
          <div className="rhs">
            {userPlan.name !== 'startg' && (
              <button onClick={handleOpenMethod}>Edit</button>
            )}
          </div>
        </div>
      </main>
    </section>
  );
};
