import RequestFund from 'components/FundRequest/Requester';
import ManageFundRequest from 'components/FundRequest/Reviewer';
import EmployeeReimbursement from 'components/ReimbursementView/Employee';
import ManageReimbursement from 'components/ReimbursementView/Manager';
import TopBar from 'components/TopBar';
import CustomButton from 'components/UI/CustomButton';
import PendingApprovals from 'pages/Approvals/PendingApprovals';
import { useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getReimbursements } from 'redux/actions/ReimbursementsAction';
import { fetchPendingApprovals } from 'redux/actions/ApprovalAction';
import { getRequests } from 'redux/actions/RequestsAction';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';

import useSegmentVisibility from 'hooks/useSegmentVisibility';

const Requests = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const locationArray = location.pathname.split('/');
  const [key, setKey] = useState(locationArray[locationArray.length - 1]);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isOtherPopoverOpen, setIsOtherPopoverOpen] = useState(false);

  const visibleSegments = useSegmentVisibility(2, 300);

  const { permissions } = allPermissions();
  const { reimbursementCode, requestCode } = useParams();

  useEffect(() => {
    if (reimbursementCode) setKey('reimbursements');
    if (requestCode) setKey('funds');
  }, []);

  const {
    getReimbursement: { data: { meta = {} } = {}, data = {} },
  } = useSelector(({ reimbursement }) => reimbursement);
  const {
    getRequest: { data: { meta: metaRequests = {} } = {}, data: requestsData = {} },
  } = useSelector(({ requests }) => requests);

  const {
    fetchPendingApprovals: { data: pendingApproval, loading, meta: approvalsMeta },
  } = useSelector(({ approval }) => approval);

  const { total } = meta;
  const { total: totalRequests } = metaRequests;
  const { reimbursements = [] } = data;
  const { requests = [] } = requestsData;

  const canViewAdmin = hasPermission({
    permissions,
    scopes: ['org-*', 'reimbursement-*', 'access-full', 'request-approve'],
  });

  const canCreate = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-create', 'reimbursement-edit'],
  });

  const canView = hasPermission({
    permissions,
    scopes: ['reimbursement-*', 'reimbursement-view'],
  });

  useEffect(() => {
    if (!reimbursements.length && canView) dispatch(getReimbursements());
    if (!requests.length && canView) dispatch(getRequests());
    if (!pendingApproval?.length) dispatch(fetchPendingApprovals());
  }, []);

  const toggleHandler = () => {
    if (key === 'rules') {
      history.push('/requests/rules/create');
    } else {
      if (!isOtherPopoverOpen) {
        setIsOpen(!isOpen);
      }
    }
  };

  useEffect(() => {
    if (location.state?.isModal) {
      toggleHandler();
      history.replace({ state: {} });
    }
  }, [location]);

  useEffect(() => {
    const type = ['reimbursements', 'funds', 'payments'].includes(key) ? key : null;
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: {
        selectedRows: [],
        type: type,
      },
    });
  }, [key]);

  const handleSelect = (tabKey) => {
    setKey(tabKey);
    if (tabKey !== 'requests') {
      history.push(
        `/requests/${tabKey}`,
        tabKey === 'payments' ? { disableFade: true } : undefined,
      );
    } else {
      history.push(`/requests`);
    }
  };

  const handleRowSelect = (rowData, type) => {
    dispatch({
      type: SELECTED_TABLE_ROWS,
      payload: { selectedRows: rowData, type },
    });
  };

  const modules = {
    reimbursements: {
      buttonTitle: 'Claim a reimbursement',
      action: null,
    },
    funds: {
      buttonTitle: 'Request funds',
      action: null,
    },
    payments: {
      buttonTitle: 'Request funds',
      action: null,
    },
  };

  return (
    <div className="position-relative container">
      <div>
        <TopBar
          headerText="Requests"
          subText="Handle all expense requests: reimbursements, fund requests, and salary advances."
          addButton={canCreate}
          dropDownBtn={
            !['rules', 'payments'].includes(key) && (
              <CustomButton className="add-button" onClick={toggleHandler}>
                {modules[key]?.buttonTitle}
              </CustomButton>
            )
          }
        />
      </div>

      <section className={loading ? ' fade-in' : ''}>
        <Row className="overview-holder shallow">
          <Col xs={12} className="mt-4">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={handleSelect}
              className="mb-2"
            >
              <Tab
                eventKey="payments"
                title={
                  <div className="d-flex">
                    Payments
                    <div className="count">
                      <span className="m-auto">{approvalsMeta?.total || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  <PendingApprovals
                    requestCode={requestCode}
                    onRowSelect={(data) => {
                      handleRowSelect(data, 'requests');
                    }}
                    handleSelect={setKey}
                  />
                </div>
              </Tab>

              <Tab
                eventKey="reimbursements"
                title={
                  <div className="d-flex">
                    Reimbursements
                    <div className="count">
                      <span className="m-auto">{total || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  {canViewAdmin && (
                    <ManageReimbursement
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'reimbursements'}
                      toggleHandler={toggleHandler}
                      reimbursementCode={reimbursementCode}
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'reimbursements');
                      }}
                    />
                  )}
                  {!canViewAdmin && (
                    <EmployeeReimbursement
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'reimbursements'}
                      toggleHandler={toggleHandler}
                      reimbursementCode={reimbursementCode}
                    />
                  )}
                </div>
              </Tab>

              <Tab
                eventKey="funds"
                title={
                  <div className="d-flex">
                    Fund Requests
                    <div className="count">
                      <span className="m-auto">{totalRequests || 0}</span>
                    </div>
                  </div>
                }
                tabClassName="new-tab"
              >
                <div className="fade-in">
                  {canViewAdmin && (
                    <ManageFundRequest
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && key === 'funds'}
                      toggleHandler={toggleHandler}
                      requestCode={requestCode}
                      onRowSelect={(data) => {
                        handleRowSelect(data, 'funds');
                      }}
                    />
                  )}
                  {!canViewAdmin && (
                    <RequestFund
                      isOtherPopoverOpen={isOtherPopoverOpen}
                      setIsOtherPopoverOpen={setIsOtherPopoverOpen}
                      isOpen={isOpen && ['funds', 'payments'].includes(key)}
                      toggleHandler={toggleHandler}
                      requestCode={requestCode}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Requests;
