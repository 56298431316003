import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';
import '../styles.scss';

import useSegmentVisibility from 'hooks/useSegmentVisibility';

const OverviewLoader = () => {
  const visibleSegments = useSegmentVisibility(5);

  return (
    <section className="page-wrapper flex-column">
      {/* Segment 1 */}
      {visibleSegments >= 1 && (
        <div
          className="d-md-flex w-100 justify-content-between mt-4 pt-2 pb-3 mb-4 fade-in"
          style={{ borderBottom: '1px solid #E8E8E7' }}
        >
          <div>
            <Skeleton.Input
              active
              size={24}
              style={{ borderRadius: 4, minWidth: '18rem' }}
            />
            <div className="mt-3">
              <Skeleton.Input
                active
                size={12}
                style={{ borderRadius: 4, minWidth: '20rem' }}
              />
            </div>
          </div>
          <div className="d-flex gap-3">
            <Skeleton.Avatar avatar active size={48} />
          </div>
        </div>
      )}

      <section className="overview-holder">
        {/* Segment 2 */}
        {visibleSegments >= 2 && (
          <div className="overview-top-data fade-in">
            <section>
              <div className="d-flex w-100">
                <div className="overview-balances__holder">
                  <p className="text">Total balance</p>
                  <p className="value mt-2">
                    <Skeleton.Input
                      active
                      size={18}
                      style={{ borderRadius: 4, minWidth: '12rem' }}
                    />
                  </p>
                </div>
                <div className="ms-auto d-flex gap-3">
                  {Array.from({ length: 3 }).map((_, i) => (
                    <Skeleton.Button
                      key={i}
                      active
                      shape="square"
                      style={{ borderRadius: 8, height: '36px', width: '8rem' }}
                    />
                  ))}
                </div>
              </div>
              <div className="mt-1 d-flex gap-3">
                {['Accounts', 'Budgets', 'NGN cards'].map((item, i) => (
                  <div key={i} className="overview-display__cards">
                    <p className="caption">{item}</p>
                    <p className="value mt-2">
                      <Skeleton.Input
                        active
                        size={16}
                        style={{ borderRadius: 4, minWidth: '11rem' }}
                      />
                    </p>
                  </div>
                ))}
              </div>
            </section>
          </div>
        )}

        <div className="row">
          <div className="budget-analytics">
            {/* Segment 3 */}
            {visibleSegments >= 3 && (
              <div className="grid-col-2 gap-4 mt-4 fade-in">
                {['Upcoming actions', 'Recent transactions'].map((item, i) => (
                  <div key={i} className="py-2 border overview-card-view barred">
                    <div
                      className="p-4"
                      style={{
                        borderBottom: '1px solid #E8E8E7',
                        color: '#212120',
                        fontWeight: 600,
                        fontSize: '1.125rem',
                      }}
                    >
                      {item}
                    </div>
                    <div className="pt-4">
                      {Array.from({ length: 5 }).map((_, i) => (
                        <div
                          key={i + 'item'}
                          className={`d-flex align-items-center gap-2 px-4 mb-4`}
                        >
                          <Skeleton.Avatar avatar active size={40} />
                          <div className="d-flex pt-2 flex-column">
                            <Skeleton.Input
                              active
                              size={12}
                              style={{ borderRadius: 4 }}
                            />
                          </div>
                          <div className="ms-auto">
                            <Skeleton.Input
                              active
                              size={12}
                              style={{ borderRadius: 4, minWidth: '8rem' }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Segment 4 */}
            {/* {visibleSegments >= 4 && (
              <div className="py-2 border overview-card-view barred mt-4 fade-in">
                <div
                  className="px-4 py-3 d-flex justify-content-between"
                  style={{
                    borderBottom: '1px solid #E8E8E7',
                    color: '#7F7F7D',
                    fontWeight: 600,
                    fontSize: '1rem',
                  }}
                >
                  <div>
                    Balance
                    <div className="mt-2">
                      <Skeleton.Input
                        active
                        size={16}
                        style={{ borderRadius: 4, minWidth: '10rem' }}
                      />
                    </div>
                  </div>
                  <Skeleton.Button
                    active
                    shape="square"
                    style={{ borderRadius: 8, height: '32px', width: '8rem' }}
                  />
                </div>
                <Skeleton.Node
                  className="p-4"
                  active
                  style={{ borderRadius: 12, width: '100%', height: '350px' }}
                >
                  <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                </Skeleton.Node>
              </div>
            )} */}

            {/* Segment 5 */}
            {visibleSegments >= 5 && (
              <div className="grid-col-2 gap-4 fade-in">
                {['Money in', 'Money out'].map((item, i) => (
                  <div key={i} className="py-2 border overview-card-view barred mt-4">
                    <div
                      className="px-4 py-3 d-flex justify-content-between"
                      style={{
                        borderBottom: '1px solid #E8E8E7',
                        color: '#7F7F7D',
                        fontWeight: 600,
                        fontSize: '1rem',
                      }}
                    >
                      <div>
                        {item}
                        <div className="mt-2">
                          <Skeleton.Input
                            active
                            size={16}
                            style={{ borderRadius: 4, minWidth: '10rem' }}
                          />
                        </div>
                      </div>
                      <Skeleton.Button
                        active
                        shape="square"
                        style={{ borderRadius: 8, height: '32px', width: '8rem' }}
                      />
                    </div>
                    <Skeleton.Node
                      className="p-4"
                      active
                      style={{ borderRadius: 12, width: '100%', height: '290px' }}
                    >
                      <DotChartOutlined style={{ fontSize: 60, color: '#bfbfbf' }} />
                    </Skeleton.Node>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </section>
  );
};

export default OverviewLoader;
