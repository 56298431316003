import React, { useEffect, useState } from 'react';
import Layout, { OverflowWrapLayout } from '../Layout';
import { AuthFormHeader } from 'components/Auth';
import { Form } from 'react-bootstrap';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import {
  AccountsIcon,
  BillsIcon,
  BudgetsIcon,
  CardsIcon,
  InvoicesIcon,
  LoadingCircle,
} from 'assets/icons/onboarding';
import CustomButton from 'components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, updateCompany } from 'redux/actions/CompaniesAction';
import { useHistory, useLocation } from 'react-router-dom';
import { sliderContents, stepsObject } from '../config';
import { get_user_profile } from 'redux/actions/AuthAction';
import { retrieveAndDecryptFromLocalStorage } from 'utils/utility';

const features = [
  {
    value: 'Accounts',
    desc: 'Insert the checkbox description here.',
    checked: true,
    icon: <AccountsIcon />,
  },
  {
    value: 'Cards',
    desc: 'Insert the checkbox description here.',
    checked: true,
    icon: <CardsIcon />,
  },
  {
    value: 'Invoices',
    desc: 'Insert the checkbox description here.',
    checked: true,
    icon: <InvoicesIcon />,
  },
  {
    value: 'Bills',
    desc: 'Insert the checkbox description here.',
    checked: true,
    icon: <BillsIcon />,
  },
  {
    value: 'Budgets',
    desc: 'Insert the checkbox description here.',
    checked: true,
    icon: <BudgetsIcon />,
  },
];
const CompanySolution = () => {
  const [feat, setFeat] = useState(features);
  const [completed, setCompleted] = useState(false);

  const dispatch = useDispatch();
  const { push } = useHistory();

  const location = useLocation();
  const token = localStorage.getItem('app-session');

  const companyCode = location?.state?.code;
  const {
    addEntity: { loading: entityLoading, success: entitySuccess },
    updateCompany: { loading, success },
    getCompany: { loading: loadingCompany, data: company },
  } = useSelector(({ companies }) => companies);

  const handleOnChange = (index) => {
    setFeat((prevFeat) =>
      prevFeat.map((item, i) =>
        i === index ? { ...item, checked: !item.checked } : item,
      ),
    );
  };

  useEffect(() => {
    if (!token) return push('/login');
  }, []);

  useEffect(() => {
    if (companyCode) dispatch(getCompany({ code: companyCode }));
  }, [companyCode]);

  useEffect(() => {
    if (success) {
      setCompleted(true);
      dispatch(
        getCompany({ code: companyCode, includeDocuments: true, includeSteps: true }),
      );
      dispatch(get_user_profile());
      setTimeout(() => {
        push('/get-started');
      }, 10000);
    }
  }, [success]);

  const handleCompanyUpdate = (type) => {
    const getCheckedFeatures = feat.reduce((acc, item) => {
      if (item.checked) {
        acc[item.value.toLowerCase()] = true;
      }
      return acc;
    }, {});

    const payload = { code: companyCode, ...getCheckedFeatures };
    dispatch(updateCompany(payload));
  };

  const selectForm = (
    <>
      {feat.map((item, index) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleOnChange(index);
          }}
          className={classNames(
            'selection-wrapper cursor',
            item.checked ? 'active' : 'inactive',
          )}
          key={index}
        >
          <div className="content-wrap">
            <div className="icon">{item.icon}</div>
            <div className="text-holder">
              <h4>{item.value}</h4>
              <p>{item.desc}</p>
            </div>
          </div>

          <Checkbox
            style={{ color: '#ffff' }}
            checked={item.checked}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleOnChange(index);
            }}
          />
        </div>
      ))}

      <div className="mt-4 py-2">
        <CustomButton
          type="button"
          fullWidth
          onClick={() => handleCompanyUpdate('selected')}
          loading={loading}
          disabled={loading}
        >
          Confirm
        </CustomButton>

        <button
          disabled={loading}
          onClick={() => handleCompanyUpdate('skipped')}
          className="btn text-center w-100 mt-3 border-0"
        >
          Skip
        </button>
      </div>
    </>
  );

  const completedView = (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column text-center justify-content-center align-items-center">
        <div className="completed-text mt-4 d-flex flex-column text-center justify-content-center align-items-center">
          <div className="loader-container mb-3">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>

          <p>Setting things up....</p>
        </div>
      </div>
    </div>
  );

  const title = 'How would you like to use Bujeti?';
  const subtitle =
    'To help improve your experience, tell us what features you plan to use on the platform.';

  return (
    <Layout
      hideSlider={completed}
      slider={sliderContents}
      menus={stepsObject('solutions')}
    >
      <AuthFormHeader
        title={completed ? null : title}
        subtitle={completed ? null : subtitle}
      />
      <OverflowWrapLayout
        height={completed ? '100%' : undefined}
        maxWidth={completed ? '580px' : undefined}
      >
        <Form className={classNames('w-100 space-y-2', { ['h-100']: completed })}>
          {completed ? completedView : selectForm}
        </Form>
      </OverflowWrapLayout>
    </Layout>
  );
};

export default CompanySolution;
