import React from 'react';
import cs from 'classnames';
import './index.scss';
import { CheckIcon, Grid9Icon } from 'assets/icons';

export const CompanyListItem = ({
  avatar,
  label,
  subLabel,
  value,
  gap = true,
  onClick,
  isActive,
}) => {
  return (
    <div
      onClick={() => onClick(value, { label, value })}
      className={cs('d-flex', 'w-100', 'align-items-start', { 'gap-2': gap })}
    >
      <div className="align-self-center">
        <Grid9Icon />
      </div>
      <div className="flex-grow-0">{avatar}</div>

      <div className="flex-grow-1 img__card-text">
        <div>
          <h5 style={{ fontWeight: '400', fontSize: '0.875rem' }}>{label}</h5>
          <span style={{ color: '#79716B', fontSize: '0.75rem' }}>{subLabel}</span>
        </div>
      </div>

      <div className="ms-auto">
        {isActive && <CheckIcon stroke="#D28B28" width="14" height="12" />}
      </div>
    </div>
  );
};

const ImgCard = ({
  avatar,
  initials,
  label,
  value,
  gap = true,
  fullWidth = true,
  size = '', //medium, normal, large, xlarge
  customProperties,
  customGap = 'gap-3',
}) => {
  const colors = ['gray', 'yellow', 'blue', 'teal', 'purple', 'red'];

  const getRandomColorClass = () => {
    const randomIndex = Math.floor(Math.random() * (colors.length + 1));
    return colors[randomIndex] || '';
  };

  const randomColorClass = getRandomColorClass();

  return (
    <div
      className={cs('d-flex', { 'w-100': fullWidth }, 'align-items-start', {
        [customGap]: customGap,
      })}
    >
      <div className="flex-grow-0">
        {avatar ? (
          avatar
        ) : (
          <div
            className={cs('img__card-initials', {
              [size]: size,
              [customProperties]: customProperties,
              [randomColorClass]: randomColorClass,
            })}
          >
            {initials}
          </div>
        )}
      </div>

      {(label || value) && (
        <div className="flex-grow-1 img__card-text">
          <h5>{label}</h5>
          <p>{value}</p>
        </div>
      )}
    </div>
  );
};

export default ImgCard;
