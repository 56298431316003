import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// we will connect our reducers here
import { AccountStatementReducer } from './AccountStatementReducer';
import { AnaylyticsReducer } from './AnalyticsReducer';
import { ApprovalReducer } from './ApprovalReducer';
import { AssetsReducer } from './AssetsReducer';
import { AuthReducer } from './AuthReducer';
import { BeneficiariesReducer } from './BeneficiariesReducer';
import { BillingReducer } from './BillingReducer';
import { BudgetsReducer } from './BudgetsReducer';
import { BvnRequestReducer } from './BvnRequestReducer';
import { CardReducer } from './CardReducer';
import { CategoryReducer } from './CategoryReducer';
import { CompaniesReducer } from './CompaniesReducer';
import { FeesReducer } from './FeesReducer';
import { IntegrationsReducer } from './IntegrationsReducers';
import { InvoiceReducer } from './InvoiceReducer';
import { NotificationReducer } from './NotificationsReducer';
import { OverviewReducer } from './OverviewReducer';
import { PaymentReducer } from './PaymentReducer';
import { PoliciesReducer } from './PoliciesReducer';
import { ProfileReducer } from './ProfileReducer';
import { ReferralsReducer } from './ReferralsReducer';
import { ReimbursementReducer } from './ReimbursementsReducer';
import { ReportsReducer } from './ReportsReducer';
import { RequestReducer } from './RequestsReducer';
import { RolesReducer } from './RolesReducer';
import { SettlementAccountReducer } from './SettlementAccount';
import { TeamsReducer } from './TeamsReducer';
import { ToggleReducer } from './ToggleReducer';
import { TableReducer } from './TableReducer';
import { TransactionReducer } from './TransactionsReducer';
import { UtilsReducer } from './UtilsReducer';
import { VendorsReducer } from './VendorsReducer';
import { ToggleExpenseReducer } from './ToggleExpenseReducer';
import { MemberProfileReducer } from './MemberProfileReducer';
import { VendorProfileReducer } from './VendorProfileReducer';
import { MonoAccountLinkingReducer } from './MonoAccountLinkingReducer';
import ProgressUploadReducer from './ProgressUploadReducer';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    budgets: BudgetsReducer,
    vendors: VendorsReducer,
    assets: AssetsReducer,
    card: CardReducer,
    beneficiaries: BeneficiariesReducer,
    transaction: TransactionReducer,
    companies: CompaniesReducer,
    profile: ProfileReducer,
    overview: OverviewReducer,
    reimbursement: ReimbursementReducer,
    toggle: ToggleReducer,
    toggleExpense: ToggleExpenseReducer,
    payments: PaymentReducer,
    notifications: NotificationReducer,
    policies: PoliciesReducer,
    categories: CategoryReducer,
    approval: ApprovalReducer,
    analytics: AnaylyticsReducer,
    teams: TeamsReducer,
    roles: RolesReducer,
    fees: FeesReducer,
    billing: BillingReducer,
    invoices: InvoiceReducer,
    accountStatement: AccountStatementReducer,
    settlementAccount: SettlementAccountReducer,
    bvnRequest: BvnRequestReducer,
    integrations: IntegrationsReducer,
    referrals: ReferralsReducer,
    reports: ReportsReducer,
    requests: RequestReducer,
    utils: UtilsReducer,
    table: TableReducer,
    memberProfile: MemberProfileReducer,
    vendorProfile: VendorProfileReducer,
    monoAccountLinking: MonoAccountLinkingReducer,
    upload: ProgressUploadReducer,
  });

const createRootReducer = (history) => (state, action) => {
  if (action?.error?.includes('complete your onboarding')) {
    state = {
      ...state,
      toggle: { ...state.toggle, toggleOnboardingNotification: true },
    };
  }

  if (action.type === 'RESET_STATE') {
    state = undefined;
  }

  return appReducer(history)(state, action);
};

export default createRootReducer;
